<template>
  <div style="position:relative">
      <v-dialog v-model="modalVisible" @click:outside="hideModal" max-width="950" scrollable>
        <v-card>
          <v-card-title>
            Registraties doorzoeken
          </v-card-title>
          <v-card-text class="background-color">
            <v-sheet class="rounded-lg my-4 pa-4 pt-1">
              <FormulateInput
                  v-if="(searchableFields.length > 0)"
                  type="group"
                  name="searchParameters"
                  v-model="searchParameters"
                  v-on:change="executeSearch"
                  :repeatable="true"
                  label="Zoekvelden"
                  add-label="+ Zoekveld toevoegen"
              >
                <!-- TODO: Get proper selects/radios/etc based on elementType -->
                <v-row>
                  <v-col>
                    <FormulateInput
                        label="Zoekveld"
                        type="select"
                        :options="searchableFields"
                        @repeatableRemoved="executeSearch"
                        @change="executeSearch"
                        name="field"
                        validation="bail|required"
                        error-behavior="live"
                    />
                  </v-col>
                  <v-col>
                    <FormulateInput
                        label="Zoekterm"
                        type="text"
                        name="query"
                        v-on:input="executeSearch"
                        :debounce="250"
                        validation="optional"
                        error-behavior="live"
                    />
                  </v-col>
                </v-row>
              </FormulateInput>
            </v-sheet>
            <v-data-table
                :headers="headers"
                :items="registrations"
                mobile-breakpoint="0"
                class="ma-md-0 mt-md-2 rounded-lg"
                :loading="tableLoading"
                :options.sync="options"
                :footer-props="{'items-per-page-options': [10, 25, 50, 100, -1]}"
                @click:row="rowClick"
            >
              <template slot="no-data">
                Er zijn geen registraties gevonden voor dit formulier met deze zoekopdracht
              </template>
              <template #item.registration_date="{ item }">
                <span>{{ new Date(item.registration_date).toLocaleString() }}</span>
              </template>
              <template #item.actions>
                <v-icon>mdi-table-arrow-right</v-icon>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-btn
                color="secondary"
                outlined
                @click="hideModal"
            >
              Sluit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    <v-btn
        class="ma-4 form-registration-search-btn"
        color="primary"
        dark
        small
        depressed
        fab
        v-on:click="showModal"
    >
      <v-icon
          dark
          aria-label="Doorzoek registraties">mdi-magnify</v-icon>
    </v-btn>
  </div>

</template>

<script>
import axios from "axios";
import eformHelpers from "../../mixins/eformHelpers";
import _ from "lodash";

export default {
  components: {
  },
  mixins: [
      eformHelpers
  ],
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      modalVisible: false,
      // searchParameters contains all the formulate search options
      // [{ field: 'text1', query: 'searchtext' }]

      // For nested fields we use the dotted approach
      // [{ field: 'subform1.textarea1', query: 'searchtext' }]
      searchParameters: [],
      registrations: [],
      headers: [
        // { text: 'ID', value: 'form_id' },
        {
          text: 'Registratie',
          align: 'start',
          sortable: true,
          value: 'registration_name',
        },
        {
          text: 'Gebruiker',
          sortable: true,
          value: 'user.username'
        },
        {
          text: 'Datum',
          sortable: true,
          value: 'registration_date'
        },
        { value: "actions", sortable: false, align: 'end' }
      ],
      tableLoading: false,
      options: {
        itemsPerPage: 10,
        sortBy: ['registration_date'],
        sortDesc: [true],
      },
    }
  },
  computed: {
    searchableFields() {
      if (Object.prototype.hasOwnProperty.call(this.form, 'searchableFields') && _.isArray(this.form.searchableFields)) {
        return this.form.searchableFields;
      }
      else {
        return [];
      }
    },
  },
  methods: {
    showModal() {
      // Hide parent form
      this.$emit('hide-parent-form');
      // Show modal
      this.modalVisible = true;
      this.executeSearch();
    },
    hideModal() {
      this.$emit('show-parent-form');
      this.modalVisible = false;
    },
    async getRecentRegistrations(params = {}) {
      this.tableLoading = true;

      axios.get('api/form/'+this.form.form_id+'/registrations/search', { params: params})
          .then(response => {
            this.registrations = this.parseRegistrations(response.data);
          })
          .catch(error => {
            console.log(error)
            this.errored = true
          })
          .finally(() => this.tableLoading = false)
    },
    executeSearch() {
      let params = {};

      this.searchParameters.forEach(function(searchParameter) {
        if (Object.prototype.hasOwnProperty.call(searchParameter, 'query') && searchParameter.query.length > 0) {
          params[searchParameter.field.replaceAll('.', ':')] = searchParameter.query;
        }
      });
      this.getRecentRegistrations(params);
    },
    parseRegistrations(registrations) {
      // Alter registrations before viewing

      // Pray I don't alter them any further!
      return registrations;
    },
    async rowClick(item) {
      this.tableLoading = true;

      let registration = await this.getRegistrationDataFromApi(item.form_id, item._id);
      await this.$emit('setFormValues', registration.values);

      this.tableLoading = false;
      this.modalVisible = false;
    },
    getRegistrationDataFromApi (form_id, registration_id) {
      // Fetch results form the API
      return axios
          .get('api/form/'+form_id+'/registrations/'+registration_id)
          .then(response => {
            return response.data
          })
          .catch(error => {
            console.log(error)
          })
    }
  },
}
</script>

<style>
.form-registration-search-btn {
  position:absolute;
  top: 0;
  right:0;
  z-index: 3;
}
</style>