<template>

  <div :key="form_id">

    <template v-if="form_in_modal">
      <v-dialog persistent v-model="form_in_modal" max-width="950" scrollable :fullscreen="$vuetify.breakpoint.mobile">
        <v-card>
          <v-card-title>{{ formName }} invullen</v-card-title>
          <v-card-text class="pt-4 text--primary background-color">
            <div v-if="renderForm">
              <FormulateForm class="formulate-style-compact" v-if="!loading" @submit="submitFormHandler" @submit-raw="submitFormHandlerRaw" @failed-validation="onValidationErrors" v-model="values" name="fillFormInModal">
                <div v-if="!loadingForm">
                  <RegistrationSearchModal
                      class="float-right"
                      v-if="registrationSearchEnabled"
                      :form="myForm"
                      @hide-parent-form="hideForm"
                      @show-parent-form="showForm"
                      @setFormValues="setFormValues"></RegistrationSearchModal>
                </div>
                <div>

                  <template v-if="loadingForm">
                    <v-skeleton-loader type="text@2, sentences, text@3, image, text@3"></v-skeleton-loader>
                  </template>

                  <v-alert
                      class="ma-4"
                      v-if="clientCredits < 1"
                      dense
                      prominent
                      type="warning"
                  >
                    De credits zijn op. U kunt dit formulier niet versturen.
                  </v-alert>

                  <v-container fluid>
                    <v-row v-for="item in conditionalSchema" :key="item.uuid">
                      <v-col class="pa-0">
                        <eform-element
                            :element="item"
                            :disabled-prop="setDisabled(item)"
                            v-show="isVisuallyShown(item)"
                            :values="values"
                            :schema="schema"
                            :form-settings="formSettings"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </div>
              </FormulateForm>
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="formulate-style-compact mb-2">
            <v-btn
                :disabled="disableSubmit"
                color="primary"
                depressed
                v-if="appOnline"
                @click="saveFormOnline('fillFormInModal')"
            >
              <template v-if="loadingForm">
                Laden...
              </template>
              <template v-else>
                Versturen
              </template>
            </v-btn>
            <v-btn
                color="secondary"
                outlined
                v-on:click.prevent="cancelFormFill"
                class="ml-3"
            >
              Annuleren
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template v-else>
      <div v-if="!loading">
        <RegistrationSearchModal
            class="float-right"
            v-if="registrationSearchEnabled"
            :form="myForm"
            @hide-parent-form="hideForm"
            @show-parent-form="showForm"
            @setFormValues="setFormValues"></RegistrationSearchModal>
      </div>
      <div v-if="renderForm">

        <div class="pt-6 px-md-8 px-4" v-if="loading">
          <v-container fluid>
            <v-row>
              <v-col class="pa-0">
                <v-skeleton-loader type="text@2, sentences, text@3, image, text@3"></v-skeleton-loader>
              </v-col>
            </v-row>
          </v-container>
        </div>

        <FormulateForm class="formulate-style-compact" v-if="!loading" @submit="submitFormHandler" @submit-raw="submitFormHandlerRaw" @failed-validation="onValidationErrors" #default="{ isLoading }" v-model="values" name="fillForm">
          <page-title :page-title="formName"></page-title>

          <div class="text-center text-lg-left">
            <div class="pl-md-8 mb-3" @click="$router.back()">
              <v-btn small outlined color="secondary">
                <v-icon left>mdi-arrow-left</v-icon> Terug
              </v-btn>
            </div>
          </div>

          <v-alert
              class="ma-4"
              v-if="clientCredits < 1"
              dense
              prominent
              type="warning"
          >
            De credits zijn op. U kunt dit formulier niet versturen.
          </v-alert>

          <div class="px-md-8 px-4">
            <v-container fluid>
              <v-row v-for="item in conditionalSchema" :key="item.uuid">
                <v-col class="pa-0">
                  <eform-element
                      :element="item"
                      :disabled-prop="setDisabled(item)"
                      v-show="isVisuallyShown(item)"
                      :values="values"
                      :schema="schema"
                      :form-settings="formSettings"
                      @subform-opened="subformOpened"
                      @subform-closed="subformClosed"
                  />
                </v-col>
              </v-row>
            </v-container>
          </div>

            <template v-if="!form_in_modal">
              <v-spacer></v-spacer>
              <v-footer padless fixed inset app class="eform-footer-transparant py-sm-2">
                <v-container fluid class="mx-md-4">
                  <v-btn
                      :disabled="disableSubmit"
                      color="primary"
                      depressed
                      v-if="appOnline"
                      @click="saveFormOnline('fillForm')"
                  >
                    <template v-if="isLoading">
                      Laden...
                    </template>
                    <template v-else>
                      Versturen
                    </template>
                  </v-btn>
                  <v-btn
                      outlined
                      color="secondary"
                      @click="saveFormOffline('fillForm')"
                      class="ml-3"
                  >
                    Opslaan
                  </v-btn>
                </v-container>
              </v-footer>
            </template>

        </FormulateForm>
      </div>
    </template>

    <v-dialog v-model="savingModal" persistent max-width="800" scrollable>
      <v-card>
        <v-card-title class="headline">
          Registratie wordt verstuurd
        </v-card-title>
        <v-card-text class="pt-4">
          <v-progress-linear
              indeterminate
              color="primary"
          ></v-progress-linear>
          <p class="text-caption text--secondary mt-5">Moment geduld alstublieft. Dit kan een tijdje duren wanneer u veel of grote afbeeldingen toegevoegd heeft.</p>
        </v-card-text>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
    <v-dialog v-model="savingComplete" max-width="400" scrollable @click:outside="goToOverview">
      <v-card>
        <v-card-text class="pt-4 text-center">
          <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/><path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/></svg>
          <p class="mt-5">Uw registratie is verstuurd.</p>
          <v-btn
              class="mx-1 my-2"
              depressed
              color="primary"
              v-on:click="goToOverview"
          >
            Gereed
          </v-btn>
          <template v-if="!form_in_modal && formType !== 'workflow'">
            <v-btn
                depressed
                outlined
                color="primary"
                v-on:click.prevent="fillFormAgain"
                class="mx-1 my-2"
            >
              Nogmaals invullen
            </v-btn>
          </template>

        </v-card-text>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </div>

</template>
<script>
import EformElement from '@/components/EformElement';
import {mapActions, mapGetters} from "vuex";
import {cloneDeep} from 'lodash';
import axios from "axios";
import eformConditionals from "@/mixins/eformConditionals";
import formulateComplexFileData from "@/mixins/formulateComplexFileData";
import PageTitle from "@/components/Pagetitle";
import store from "@/plugins/store";
import clientHelpers from "@/mixins/clientHelpers";
import RegistrationSearchModal from "../../components/search/RegistrationSearchModal";
import eformHelpers from "../../mixins/eformHelpers";
import eformFormFillHelpers from "@/mixins/eformFormFillHelpers";

export default {
  name: "Form",
  components: {
    RegistrationSearchModal,
    EformElement,
    PageTitle,
  },
  mixins: [
    formulateComplexFileData,
    eformFormFillHelpers,
    eformConditionals,
    eformHelpers,
    clientHelpers,
  ],
  props: {
    form_id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false, // Indicated if the form is uploading files
      flagSaveFormWithoutSending: false,
      flagSaveFormRedirect: false,
      autoSavePolling: null,
      subformModalIsOpen: false,
      autoSaveCooldown: Date.now(),
    }
  },
  created() {
    // Start auto save polling
    this.pollAutosave();
    // Force reload page
    if (this.force_reload === '1') {
      console.log('Force reload of app when going to form-fill page to prevent memory leaks and hopefully to fix the iOS file upload bug.')
      // Change URL hash, set 'force_reload' to false
      window.location.hash = 'form/'+this.form_id+'/0'
      // Reload entire app to prevent caches
      window.location.reload()
    }
  },
  async mounted() {
    if (this.force_reload !== '1') {
      // Get form from state
      let form = this.getFormByID(this.form_id)
      await this.initializeForm(form);
    }
  },
  computed: {
    ...mapGetters(["appOnline", "getFormByID", "userIsFiller", "getRegistrationByFillToken"]),
  },
  methods: {
    ...mapActions(["fillForm"]),
    saveFormOnline(formName) {
      // Set flag
      this.flagSaveFormWithoutSending = false
      // Trigger submit of named Vue Formulate form
      this.$formulate.submit(formName);
    },
    saveFormOffline(formName) {
      // Set flags
      this.flagSaveFormWithoutSending = true
      this.flagSaveFormRedirect = true
      // Trigger submit of named Vue Formulate form
      this.$formulate.submit(formName);
    },
    saveFormOfflineWithoutRedirect(formName) {
      // Set flags
      this.flagSaveFormWithoutSending = true
      this.flagSaveFormRedirect = false
      // Trigger submit of named Vue Formulate form
      this.$formulate.submit(formName);
      // Remove validation errors after submitting the form. We don't want to see them when auto-saving the form.
      this.$formulate.resetValidation(formName);
    },
    async getDataFromApi() {
      this.loadingForm = true
      // Fetch results form the API
      await axios
          .get('api/form/' + this.form_id)
          .then(response => {
            this.updateForm(response.data);
            this.loadingForm = false;
          })
          .catch(error => {
            console.log(error)
            this.errored = true
          })
          .finally(() => this.loadingForm = false)
    },
    // Save form online or offline
    submitFormHandler(formValues) {
      // Make a copy of the formValues to remove cyclical values
      let formValuesCopy = cloneDeep(formValues);
      // Make sure file arrays have a multidimensional array (an array inside an array).
      // This ugly fix is needed because we can only set default values for a file input
      this.fileValuesConvertSingleToMultiple(this.schema, formValuesCopy)

      if (this.appOnline === true && this.flagSaveFormWithoutSending === false) {
        this.$toast.clear();
        this.submitFormOnline(formValuesCopy)
      }
    },
    async submitFormHandlerRaw(formSubmission) {
      let formValues = await formSubmission.values()

      // Make a copy of the formValues to remove cyclical values
      let formValuesCopy = cloneDeep(formValues);
      // Make sure file arrays have a multidimensional array (an array inside an array).
      // This ugly fix is needed because we can only set default values for a file input
      this.fileValuesConvertSingleToMultiple(this.schema, formValuesCopy)

      if (this.flagSaveFormWithoutSending === true) {
        //console.log('SubmitFormOffline..')
        this.submitFormOffline(formValuesCopy)
      }
    },
    async commitFormToStore(formCopy) {
      await this.fillForm({
        form: formCopy,
      });
    },
    submitFormOffline(formValues) {
      // Make a copy of the form
      let formCopy = cloneDeep(this.myForm);
      // Override values with the formValues to prevent complex file data.
      formCopy.values = formValues;
      // Commit the form to the unsynced registrations
      store.commit('createOrUpdateSavedRegistration', {
        form: formCopy
      });

      if (this.flagSaveFormRedirect) {
        this.$toast.success("Formulier is opgeslagen om later verzonden te worden");

        // Allow user to leave page without a confirm box
        this.unsavedChanges = false

        if (!this.form_in_modal) {
          this.$router.push({name: "unsend_registration_list"});
        }
        else {
          this.$emit('closeModal');
        }
      }
    },
    async onValidationErrors() {
      if (!this.flagSaveFormWithoutSending) {
        this.$toast.error("Controleer het formulier. Sommige velden zijn niet of verkeerd ingevuld.", { timeout: false });
      }
    },
    pollAutosave () {
      let $vm = this;
      this.autoSavePolling = setInterval(() => {
        if (!$vm.isLoading && !$vm.savingModal && !$vm.savingComplete && !$vm.loading && $vm.renderForm) {
          // Auto save the registration (offline) every 30 seconds
          $vm.autoSaveForm();
        }
      }, 30000)
    },
    autoSaveForm() {
      let cooldownHasElapsed = Date.now() > this.autoSaveCooldown;
      // Prevent auto-save when:
      // - An subform is opened
      // - The autosave cooldown has not elapsed yet, to make sure we don't autosave twice in rapid succession. For example: when the timer triggers the autosave ánd the close-subform-modal triggers the autosave.
      // - The form is saving/submitting online (this.savingModal)
      // - The form saving is completed (this.savingComplete)
      // - The form is loading
      // - The form is shown (this.renderForm must be true)
      if (!this.subformModalIsOpen && cooldownHasElapsed && !this.isLoading && !this.savingModal && !this.savingComplete && !this.loading && this.renderForm) {
        console.log('Autosave has triggered')
        // Set time a cooldown time. When this time has elapsed its possible to save again.
        this.autoSaveCooldown = Date.now() + 15000; // Cooldown of 15 seconds
        // Save the registration (offline)
        this.saveFormOfflineWithoutRedirect('fillForm');
      }
    },
    /**
     * Load the assigned registration
     *
     * @param registration_id
     */
    async loadAssignedRegistration(registration_id) {
      let registration = null;
      // Fetch results form the API
      await axios
          .get('api/assigned-registration/' + registration_id)
          .then(response => {
            registration = response.data;
          })
          .catch(error => {
            console.log(error)
          })
      return registration;
    },
  },
  beforeDestroy () {
    clearInterval(this.autoSavePolling)
  },
}
</script>

<style lang="scss">
// Use the compact style
@import '@/styles/formulate-compact.scss';

.v-card__actions .formulate-input {
  margin-bottom: 0em !important;
}
// Colors
$green: var(--v-success-base);
$primary: var(--v-primary-base);
$white: #fff;

// Misc
$curve: cubic-bezier(0.650, 0.000, 0.450, 1.000);

.checkmark {
  width: 56px;
  height: 56px;
  margin: 0 auto;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: $white;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px $primary;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: $primary;
  fill: none;
  animation: stroke .6s $curve forwards;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke .3s $curve .8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px $primary;
  }
}
</style>