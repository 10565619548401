<template>
  <div v-if="loading">
    <v-skeleton-loader type="heading"></v-skeleton-loader>
    <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
  </div>
  <div v-else>
    <eform-tokens :tokens="this.getTokensFromSchemaFilterInclude(myForm.schema, ['text', 'textarea', 'number', 'email', 'date', 'time', 'datetime', 'checkbox_single', 'checkbox_multiple', 'radio', 'select'])"></eform-tokens>

    <FormulateForm name="formRules" v-model="myForm" @submit="submitForm" @failed-validation="onValidationErrors">

      <FormulateInput
          type="group"
          name="rules"
          help="Hiermee kunt u velden verbergen, tonen of waardes invullen onder bepaalde condities."
          :repeatable="true"
          label="Regels"
          add-label="+ Regel toevoegen"
          #default="rule"
      >

        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header v-slot="{ open }">
              <v-row no-gutters>
                <v-col cols="3">
                  Regel
                </v-col>
                <v-col cols="7" class="text--secondary">
                  <v-row no-gutters v-if="!open">
                    <v-col>
                      <template v-if="rules[rule.index]">
                        {{ rules[rule.index].ruleName }}
                      </template>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>

              <!--        Feature action index = {{ feature_action_index }}-->

              <FormulateInput
                  type="text"
                  label="Regelnaam"
                  help="Voorbeeld: Toon veld wanneer veld X aangevinkt is."
                  name="ruleName"
                  validation="bail|required"
                  :debounce="100"
              />

              <FormulateInput
                  type="select"
                  label="Formulier"
                  :options="ruleFormOptions"
                  @change="ruleFormChanged(rule)"
                  help="Selecteer hier of de conditie bij het hoofd- of bij een subformulier hoort"
                  name="ruleForm"
                  validation="bail|required"
              />

              <v-row>
                <v-col>
                  <FormulateInput
                      type="group"
                      :key="rule.ruleForm"
                      name="condition_group"
                      label="Als"
                  >
                    <FormulateInput
                        type="group"
                        name="conditions"
                        :repeatable="true"
                        label="Condities"
                        add-label="+ Conditie toevoegen"
                        #default="conditions"
                    >
<!--                      <FormulateInput-->
<!--                          :options="{'dataValue': 'Formulier veld', 'registrationValue': 'Registratie veld'}"-->
<!--                          type="select"-->
<!--                          name="conditionType"-->
<!--                          validation="required"-->
<!--                          :debounce="100"-->
<!--                      />-->
                      <FormulateInput
                          type="hidden"
                          name="conditionType"
                          value="dataValue"
                      />

                      <template v-if="
                        rules[rule.index]
                        &&
                        rules[rule.index].condition_group
                        &&
                        rules[rule.index].condition_group[0]
                         &&
                         rules[rule.index].condition_group[0].conditions
                         &&
                         rules[rule.index].condition_group[0].conditions[conditions.index]
                         &&
                         rules[rule.index].condition_group[0].conditions[conditions.index].conditionType
                         &&
                         rules[rule.index].condition_group[0].conditions[conditions.index].conditionType === 'dataValue'">

                        <!-- Select dataname/field -->
                        <FormulateInput
                            type="select"
                            name="dataName"
                            placeholder="Datanaam"
                            :options="getDataNamesOptionsCondition(rules[rule.index])"
                            validation="bail|required"
                            :debounce="100"
                        />

                        <!--          met de waarde-->
                        <template v-if="rules[rule.index].condition_group[0].conditions[conditions.index].dataName">
                          <FormulateInput
                              :options="operators(rules[rule.index].condition_group[0].conditions[conditions.index].dataName, rules[rule.index])"
                              type="select"
                              name="operator"
                              validation="required"
                              :debounce="100"
                          />

                          <template v-if="
                            rules[rule.index]
                            &&
                            rules[rule.index].condition_group
                            &&
                            rules[rule.index].condition_group[0]
                             &&
                             rules[rule.index].condition_group[0].conditions
                             &&
                             rules[rule.index].condition_group[0].conditions[conditions.index]
                             &&
                             rules[rule.index].condition_group[0].conditions[conditions.index].dataName
                             &&
                             operatorNeedsValue(rules[rule.index].condition_group[0].conditions[conditions.index].operator)">

                            <!-- Check what type of value input we need (select or text) -->
                            <template v-if="valueInputType(rules[rule.index].condition_group[0].conditions[conditions.index].dataName) === 'number'">
                              <FormulateInput
                                  type="decimal"
                                  inputmode="decimal"
                                  help="waarde"
                                  name="value"
                                  :validation="[['number'],['required']]"
                                  :debounce="100"
                              />
                            </template>
                            <template v-else>
                              <FormulateInput
                                  type="select"
                                  help="waarde"
                                  name="value"
                                  :options="getElementValues(rules[rule.index].condition_group[0].conditions[conditions.index].dataName, rules[rule.index])"
                                  validation="bail|required"
                                  :debounce="100"
                              />
                            </template>
                          </template>
                        </template>
                      </template>




                    </FormulateInput>

                    <!--              Feature action index = {{ conditions_index }}-->

                    <FormulateInput
                        label="Welke condities moeten waar zijn?"
                        :options="{'AND': 'Alle condities (AND)', 'OR': 'Één conditie (OR)'}"
                        type="select"
                        name="method"
                        validation="required"
                        :debounce="100"
                    />
                  </FormulateInput>


                </v-col>
                <v-col>

                  <FormulateInput
                      type="group"
                      name="actions"
                      :key="rule.ruleForm"
                      :repeatable="true"
                      add-label="+ Actie toevoegen"
                      label="Dan"
                      #default="actions"
                  >
                    <FormulateInput
                        :options="{'show': 'Toon veld', 'hide': 'Verberg veld', 'visually_hidden': 'Verberg veld visueel', 'set': 'Waarde instellen'}"
                        type="select"
                        name="method"
                        validation="required"
                        :debounce="100"
                    />
                    <FormulateInput
                        type="select"
                        name="dataName"
                        placeholder="Datanaam"
                        :options="getDataNamesOptionsAll(rules[rule.index])"
                        validation="bail|required"
                        :debounce="100"
                    />
                    <template v-if="
                    rules[rule.index] &&
                    rules[rule.index].actions &&
                    rules[rule.index].actions[actions.index] &&
                    rules[rule.index].actions[actions.index].method === 'set'">
                      <template v-if="
                            rules[rule.index] &&
                            rules[rule.index].actions &&
                            rules[rule.index].actions[actions.index] &&
                            ['select','radio'].includes(getDataNameElementType(rules[rule.index].actions[actions.index].dataName, rules[rule.index]))">
                        <FormulateInput
                            type="select"
                            name="value"
                            :options="getElementValues(rules[rule.index].actions[actions.index].dataName, rules[rule.index])"
                            validation="bail|required"
                            help="Waarde"
                            :debounce="100"
                        />
                      </template>
                      <template v-else>
                        <FormulateInput
                            type="text"
                            name="value"
                            validation="bail|required"
                            help="Waarde. Dit veld ondersteund tokens."
                            :debounce="100"
                        />
                      </template>

                    </template>
                  </FormulateInput>

                </v-col>
              </v-row>


            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

      </FormulateInput>


      <v-footer padless fixed inset app class="eform-footer-transparant py-2">
        <v-container fluid>
          <FormulateInput
              type="submit"
              input-class="v-btn v-size--default success"
              :disabled="isSaving"
              :label="isSaving ? 'Laden...' : 'Regels opslaan'"
          />
        </v-container>
      </v-footer>


    </FormulateForm>
  </div>
</template>
<script>
import axios from "axios";
import eformHelpers from "@/mixins/eformHelpers";
import eformTokens from "@/components/EformTokens";
import _ from "lodash";

export default {
  name: "FormRules",
  components: {
    eformTokens,
  },
  mixins: [
    eformHelpers
  ],
  data() {
    return {
      loading: true,
      isSaving: false,
      myForm: null,
      form_id: this.$route.params.form_id,
      unsavedChanges: true,
      datanamesOptions: {},
      datanamesOptionsCondition: {},
    }
  },
  computed: {
    ruleFormOptions() {
      let options = [];
      options.push({
        value: '_none',
        label: 'Hoofdformulier',
      });
      let subFormElements = this.getElementsFromSchemaFiltered(this.myForm.schema, ['subform']);

      subFormElements.forEach(function(subform) {
        options.push({
          value: subform.name,
          label: subform.label,
        });
      });

      return options;
    },
    rules: {
      get() {
        if (this.myForm && this.myForm.rules) {
          return this.myForm.rules;
        }
        return [];
      },
      set(values) {
        this.myForm.rules = values;
      }
    },
  },
  mounted () {
    // Get form from API
    this.getDataFromApi()
  },
  methods: {
    getDataNamesOptionsCondition(rule) {
      let options = [];
      // Add dataname options
      let dataname_options = this.datanamesOptionsCondition[this.getRuleForm(rule)];
      // Add the dataname options
      for (let i = 0; i < dataname_options.length; i++) {
        options.push(dataname_options[i]);
      }
      if (this.myForm.form_type === 'workflow') {
        // Registration options
        options.push({
          value: 'registration_phase',
          label: 'Registratie fase',
        });
      }
      return options;
    },
    getDataNamesOptionsAll(rule) {
      return this.datanamesOptions[this.getRuleForm(rule)];
    },
    getRuleForm(rule) {
      if (_.isPlainObject(rule) && Object.prototype.hasOwnProperty.call(rule, 'ruleForm')) {
        return rule.ruleForm;
      }

      return '_none';
    },
    getDataNameElementType(dataname, rule = '') {
      if (!_.isEmpty(rule.ruleForm) && rule.ruleForm !== '_none') {
        let subform = this.getElementByName(this.myForm.schema, rule.ruleForm);
        return this.getDatanames(subform.subform).find(x => x.dataname === dataname).elementType;
      }

      let element = this.getDatanames(this.myForm).find(x => x.dataname === dataname);
      if (element) {
        return element.elementType
      }
      return '';
    },
    ruleFormChanged(rule) {
      this.$set(this.rules[rule.index], 'actions', [{method: 'show'}]);
      this.$set(this.rules[rule.index], 'condition_group', [{conditions: [{conditionType: 'dataValue'}], method: 'show'}]);
    },
    operatorNeedsValue(operator) {
      let operatorsThatNeedValues = ['=', '!', '>', '>=', '<', '<=', 'contains', '!contains'];

      return (operatorsThatNeedValues.includes(operator));
    },
    valueInputType(dataname) {
      let element = this.getElementByName(this.myForm.schema, dataname);

      if (dataname === 'registration_phase') {
        return 'number';
      }

      // Elements that can have a number input
      let numberInputTypes = ['number','calculation'];

      if (element && element.elementType && numberInputTypes.includes(element.elementType)) {
        return 'number';
      }
      else {
        return 'select';
      }
    },
    operators(dataname, rule = '') {
      let elementType = this.getDataNameElementType(dataname, rule);

      if (elementType === 'number' || elementType === 'calculation' || dataname === 'registration_phase') {
        return {
          '=': 'is gelijk aan',
          '!': 'is niet gelijk aan',
          '>': 'is groter dan',
          '>=': 'is groter dan of gelijk aan',
          '<': 'is kleiner dan',
          '<=': 'is kleiner dan of gelijk aan',
          'empty': 'is leeg',
          '!empty': 'is ingevuld',
        }
      }

      switch (elementType) {
        case 'checkbox_single':
          return {
            'empty': 'is leeg',
            '!empty': 'is ingevuld',
          }
        case 'checkbox_multiple':
          return {
            '=': 'is gelijk aan',
            '!': 'is niet gelijk aan',
            'empty': 'is leeg',
            '!empty': 'is ingevuld',
            'contains': 'bevat waarde',
            '!contains': 'bevat NIET de waarde',
          }
        default:
          // Fallback, allow options that can apply to every type of element
          return {
            '=': 'is gelijk aan',
            '!': 'is niet gelijk aan',
            'empty': 'is leeg',
            '!empty': 'is ingevuld',
          }
      }
    },
    getElementValues(dataName, rule) {

      if (rule && rule.ruleForm && rule.ruleForm !== '_none') {
        let subform = this.getElementByName(this.myForm.schema, rule.ruleForm);
        // Find element in form schema and return the options
        for (let i in subform.subform.schema) {
          if (subform.subform.schema[i].name === dataName && subform.subform.schema[i].options) {
            return subform.subform.schema[i].options;
          }
        }
        return [];
      }

      // Find element in form schema and return the options
      for (let i in this.myForm.schema) {
        if (this.myForm.schema[i].name === dataName && this.myForm.schema[i].options) {
          return this.myForm.schema[i].options;
        }
      }
      return [];
    },
    getDataFromApi () {
      this.loading = true
      // Fetch results form the API
      axios
          .get('api/form/'+this.form_id)
          .then(response => {
            this.myForm = response.data
            this.loading = false;

            // Vue Formulate bugfix: Remove the first item when it's empty.
            this.$nextTick(function(){
              if (!this.rules.length) {
                this.rules = []
              }
            })

            this.setDataNames();
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => this.loading = false)
    },
    setDataNames() {
      let dataNames = {};
      let dataNamesConditions = {};
      let ruleFormOptions = this.ruleFormOptions;
      for (let i = 0; i < ruleFormOptions.length; i++) {
        if (ruleFormOptions[i].value === '_none') {
          dataNames[ruleFormOptions[i].value] = this.getDatanamesAsSelectList(this.myForm);
          dataNamesConditions[ruleFormOptions[i].value] = this.getDatanamesAsSelectList(this.myForm, ['checkbox_single','checkbox_multiple', 'radio', 'select', 'number', 'calculation']);
        }
        else {
          let subform = this.getElementByName(this.myForm.schema, ruleFormOptions[i].value);
          dataNames[ruleFormOptions[i].value] = this.getDatanamesAsSelectList(subform.subform);
          dataNamesConditions[ruleFormOptions[i].value] = this.getDatanamesAsSelectList(subform.subform, ['checkbox_single','checkbox_multiple', 'radio', 'select', 'number', 'calculation']);
        }
      }
      this.datanamesOptions = dataNames;
      this.datanamesOptionsCondition = dataNamesConditions;
    },
    // Send the form to the API endpoint
    async submitForm(formValues) {
      let $vm = this
      this.isSaving = true;
      console.log('submitForm');
      console.log(formValues);

      for (let i = 0; i < formValues.schema.length; i++) {
        if (formValues.schema[i].elementType && formValues.schema[i].elementType === 'subform') {
          let subformRules = formValues.rules.filter(({ ruleForm }) => ruleForm === formValues.schema[i].name);
          formValues.schema[i].subform.rules = subformRules;
          console.log('subformRules: ');
          console.log(subformRules);
        }
      }

      // Save form
      axios.patch('api/form/'+this.form_id, formValues)
          .then(response => {
            this.myForm = response.data
            $vm.$toast.success("Regels zijn opgeslagen");
          })
          .catch(function (error) {
            $vm.$toast.error("Regels konden niet worden aangepast.");
            // handle error
            console.log(error);
          })
          .finally(function() {
                $vm.isSaving = false
              }
          )

    },
    async onValidationErrors() {
      this.$toast.error("Controleer het formulier. Sommige velden zijn niet of verkeerd ingevuld.");
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.unsavedChanges) {
      const answer = window.confirm('Wilt u deze pagina verlaten? Indien u wijzigingen heeft aangebracht gaan deze verloren.')
      if (answer) {
        next()
        return
      } else {
        next(false)
        return
      }
    }
    next()
  }
}
</script>

<style scoped>

</style>