<template>
<div>
  <v-card flat class="pa-4 rounded-lg">
    <v-card-title>Groep</v-card-title>

      <v-row v-if="isLoading">
        <v-col cols="8">
          <v-sheet class="pa-4 rounded-lg">
            <v-skeleton-loader type="sentences, text@3, image"></v-skeleton-loader>
          </v-sheet>
        </v-col>
      </v-row>

      <v-card-text v-if="!isLoading">

        <v-alert
            type="error"
            v-bind:key="key"
            v-for="(errorMessage, key) in errorMessages"
            dismissible
        >
          {{ errorMessage }}
        </v-alert>

        <FormulateForm #default="{ isSaving }" @submit="submitForm">

          <FormulateInput
              type="text"
              label="Groepnaam"
              v-model="group.label"
              validation="required"
          />

          <FormulateInput
              input-class="v-btn v-size--default success"
              type="submit"
              name="submit"
              :disabled="isSaving"
              :label="isSaving ? 'Laden...' : 'Groep opslaan'"
              class="mt-5"
          />
        </FormulateForm>

    </v-card-text>
  </v-card>

  <v-expansion-panels flat class="mt-6 rounded-lg">
    <v-expansion-panel>
      <v-expansion-panel-header>
        <v-card-title class="pa-2">
          <span class="mr-4">Formulieren</span>
          <p class="caption ma-0">Formulieren waar deze group toegang tot heeft</p>
        </v-card-title>
      </v-expansion-panel-header>
      <v-expansion-panel-content>

        <v-data-table
            :headers="this.user_group_forms_headers"
            :items="this.user_group_forms"
            mobile-breakpoint="0"
            sort-by="form_name"
            :options="{itemsPerPage: -1}"
        >
          <template #item.actions="{item}">
            <v-btn class="ml-2" outlined small color="primary" router :to="{ name: 'form_view', params: {form_id: item.form_id} }">
              <v-icon
                  depressed
                  role="button"
                  left
              >mdi-text-box-outline</v-icon>
              Bekijk formulier
            </v-btn>
          </template>
        </v-data-table>

      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>


  <v-expansion-panels flat class="mt-6 rounded-lg">
    <v-expansion-panel>
      <v-expansion-panel-header @click="toggleAddUsersPanel">
        <v-card-title class="pa-2">
          <span class="mr-4">Gebruikers toevoegen</span>
          <p class="caption ma-0">Voeg een gebruiker toe aan deze groep.</p>
        </v-card-title>
      </v-expansion-panel-header>
      <v-expansion-panel-content>

        <v-data-table
            :headers="client_users_headers"
            :items="this.clientUsersNotInThisGroup"
            mobile-breakpoint="0"
            :loading="isLoadingClientUsers"
            sort-by="display_name"
            :options="{itemsPerPage: -1}"
        >
          <template #item.actions="{item}">
            <v-btn class="ml-2" outlined small color="primary" @click.stop="addUserToUserGroup(item)">
              <v-icon
                  depressed
                  role="button"
                  left
              >mdi-plus</v-icon>
              Toevoegen aan groep
            </v-btn>
          </template>
        </v-data-table>

      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>

  <v-card flat class="pa-4 mt-6 rounded-lg">
    <v-card-title>
      <span class="mr-4">Gebruikers</span>
      <p class="caption ma-0">Deze gebruikers horen bij de groep "{{ group.label }}". Deze gebruikers hebben toegang tot alle formulieren waar deze groep bij hoort.</p>
    </v-card-title>
    <v-card-text>

      <v-data-table
          :headers="user_headers"
          :items="group.users"
          mobile-breakpoint="0"
          :loading="isLoading"
          sort-by="display_name"
          :options="{itemsPerPage: -1}"
      >
        <template #item.actions="{item}">
          <v-btn class="ml-2" outlined small color="red" @click.stop="removeUserFromGroupConfirm(item)" :disabled="isSaving">
            <v-icon
                depressed
                role="button"
                left
            >mdi-trash-can</v-icon>
            Verwijder uit groep
          </v-btn>
        </template>
      </v-data-table>

    </v-card-text>
  </v-card>
</div>
</template>

<script>
import axios from "@/plugins/axios";
import router from "@/plugins/routes";
import normalize from "json-api-normalize";
import userEntityHelpers from "@/mixins/userEntityHelpers";
import loader from "@/mixins/loader";

export default {
  mixins: [
    userEntityHelpers,
    loader,
  ],
  props: {
    client_id: null,
    group_id: null,
  },
  data () {
    return {
      group: {},
      client_users: [],
      client_users_headers: [
        {
          text: 'Gebruiker',
          align: 'start',
          sortable: true,
          value: 'display_name',
        },
        { value: "actions", sortable: false, align: 'end' }
      ],
      isLoadingClientUsers: true,
      errorMessages: [],
      name: '',
      user_headers: [
        {
          text: 'Gebruiker',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        { value: "actions", sortable: false, align: 'end' }
      ],
      user_group_forms: [],
      user_group_forms_headers: [
        {
          text: 'Formulier',
          align: 'start',
          sortable: true,
          value: 'form_name',
        },
        { value: "actions", sortable: false, align: 'end' }
      ],
    }
  },
  computed: {
    clientUsersNotInThisGroup() {
      let clientUsers = []
      // Loop over all client users
      clientLoop: for (const user of this.client_users) {
        if (user.field_user_groups) {
          // Check if user already belongs to this user group
          for (const user_group of user.field_user_groups) {
            if (user_group.id === this.group_id) {
              continue clientLoop
            }
          }
        }
        clientUsers.push(user)
      }
      return clientUsers
    }
  },
  async mounted () {
    // Load user
    await this.getUserDataFromApi()
    // Get forms this user group belongs to
    await this.getUserGroupFormsFromApi()
    // Loading is done
    this.hideLoader()
  },
  methods: {
    toggleAddUsersPanel() {
      // Load client user when not loaded yet.
      if (this.client_users.length < 1) {
        this.getClientUsersFromApi()
      }
    },
    async getUserDataFromApi() {
      try {
        // Fetch group
        let config = {
          params: {
            'include': 'users',
          }
        }
        let response = await axios.get('jsonapi/user_group/user_group/'+this.group_id, config);

        // Normalize the entities
        this.group = normalize(response.data).get([
          'label',
          'users.name',
          'users.id',
        ])

      } catch (error) {
        console.log(error);
      }
    },
    async getClientUsersFromApi() {
      try {
        // Get all users that belong to this client
        let config = {
          params: {
            'filter[client-filter][path]': 'field_client.id',
            'filter[client-filter][operator]': '=',
            'filter[client-filter][value]': this.client_id,
          }
        }
        // Fetch clients
        let response = await axios.get('jsonapi/user/user', config);

        this.isLoadingClientUsers = false

        // Normalize the entities
        this.client_users = normalize(response.data).get([
          'display_name',
          'id',
          'field_user_groups.id',
        ])

      } catch (error) {
        console.log(error);
      }
    },
    async getUserGroupFormsFromApi() {
      try {
        // Fetch forms the user group is assigned to
        this.user_group_forms = (await axios.get('api/user-groups/' + this.group_id + '/forms')).data;

      } catch (error) {
        console.log(error);
      }
    },
    // Send the form to the API endpoint
    async submitForm() {
      this.showSaving()

      let config = {
        headers: {
          "Accept": "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json",
        }
      }
      let payload = {
        data: {
          type: 'user_group--user_group',
          id: this.group_id,
          attributes: {
            label: this.group.label,
          },
        }
      }

      // Patch the resource with the payload
      axios.patch('jsonapi/user_group/user_group/'+this.group_id, payload, config)
          .then(() => {
            this.$toast.success("Groep '" + this.name + "' is aangepast.");
            router.push({ name: 'client_user_groups', params: {client_id: this.client_id} })
          })
          .catch(error => {
            if (error.response && error.response.data) {
              // Show error message
              if (error.response.data.errors) {
                // Add error messages
                this.errorMessages = error.response.data.errors;
              }
            }
          })
          .finally(() => this.hideSaving())

    },
    async removeUserFromGroupConfirm(user) {
      if (
          confirm('Weet je zeker dat je de gebruiker "'+user.name+'" wilt verwijderen uit deze groep?')
      ) {
        await this.removeUserFromGroup(user);
      }
    },
    async removeUserFromGroup(user) {
      this.showSaving()

      try {
        // Remove user from group
        await axios.delete('api/user-groups/'+this.group_id+'/remove-user/'+user.id);
        this.showLoaders()
        // Get new data
        await this.getUserDataFromApi()
        await this.getClientUsersFromApi()
        this.hideLoaders()
        this.$toast.success("Gebruiker is verwijderd van groep.");
      } catch (error) {
        console.log(error);
        this.$toast.error("Gebruiker kon niet worden verwijderd.");
      } finally {
        this.hideSaving()
      }

    },
    async addUserToUserGroup(user) {
      this.showSaving()

      try {
        // Add user to group
        await axios.post('api/user-groups/'+this.group_id+'/add-user/'+user.id, [user.id]);
        this.showLoaders()
        // Get new data
        await this.getUserDataFromApi()
        await this.getClientUsersFromApi()
        this.hideLoaders()
        this.$toast.success("Gebruiker is toegevoegd aan groep.");
      } catch (error) {
        console.log(error);
        this.$toast.error("Gebruiker kon niet worden toegevoegd.");
      } finally {
        this.hideSaving()
      }
    },
    showLoaders() {
      this.isLoadingClientUsers = true
      this.showLoader()
    },
    hideLoaders() {
      this.isLoadingClientUsers = false
      this.hideLoader()
    }
  }
}
</script>

<style scoped>

</style>