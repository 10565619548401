var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading)?_c('div',[_c('v-skeleton-loader',{attrs:{"type":"heading"}}),_c('v-skeleton-loader',{attrs:{"type":"list-item-three-line"}})],1):_c('div',[_c('eform-tokens',{attrs:{"tokens":this.getTokensFromSchemaFilterInclude(_vm.myForm.schema, ['text', 'textarea', 'number', 'email', 'date', 'time', 'datetime', 'checkbox_single', 'checkbox_multiple', 'radio', 'select'])}}),_c('FormulateForm',{attrs:{"name":"formRules"},on:{"submit":_vm.submitForm,"failed-validation":_vm.onValidationErrors},model:{value:(_vm.myForm),callback:function ($$v) {_vm.myForm=$$v},expression:"myForm"}},[_c('FormulateInput',{attrs:{"type":"group","name":"rules","help":"Hiermee kunt u velden verbergen, tonen of waardes invullen onder bepaalde condities.","repeatable":true,"label":"Regels","add-label":"+ Regel toevoegen"},scopedSlots:_vm._u([{key:"default",fn:function(rule){return [_c('v-expansion-panels',[_c('v-expansion-panel',[_c('v-expansion-panel-header',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var open = ref.open;
return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"3"}},[_vm._v(" Regel ")]),_c('v-col',{staticClass:"text--secondary",attrs:{"cols":"7"}},[(!open)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[(_vm.rules[rule.index])?[_vm._v(" "+_vm._s(_vm.rules[rule.index].ruleName)+" ")]:_vm._e()],2)],1):_vm._e()],1)],1)]}}],null,true)}),_c('v-expansion-panel-content',[_c('FormulateInput',{attrs:{"type":"text","label":"Regelnaam","help":"Voorbeeld: Toon veld wanneer veld X aangevinkt is.","name":"ruleName","validation":"bail|required","debounce":100}}),_c('FormulateInput',{attrs:{"type":"select","label":"Formulier","options":_vm.ruleFormOptions,"help":"Selecteer hier of de conditie bij het hoofd- of bij een subformulier hoort","name":"ruleForm","validation":"bail|required"},on:{"change":function($event){return _vm.ruleFormChanged(rule)}}}),_c('v-row',[_c('v-col',[_c('FormulateInput',{key:rule.ruleForm,attrs:{"type":"group","name":"condition_group","label":"Als"}},[_c('FormulateInput',{attrs:{"type":"group","name":"conditions","repeatable":true,"label":"Condities","add-label":"+ Conditie toevoegen"},scopedSlots:_vm._u([{key:"default",fn:function(conditions){return [_c('FormulateInput',{attrs:{"type":"hidden","name":"conditionType","value":"dataValue"}}),(
                        _vm.rules[rule.index]
                        &&
                        _vm.rules[rule.index].condition_group
                        &&
                        _vm.rules[rule.index].condition_group[0]
                         &&
                         _vm.rules[rule.index].condition_group[0].conditions
                         &&
                         _vm.rules[rule.index].condition_group[0].conditions[conditions.index]
                         &&
                         _vm.rules[rule.index].condition_group[0].conditions[conditions.index].conditionType
                         &&
                         _vm.rules[rule.index].condition_group[0].conditions[conditions.index].conditionType === 'dataValue')?[_c('FormulateInput',{attrs:{"type":"select","name":"dataName","placeholder":"Datanaam","options":_vm.getDataNamesOptionsCondition(_vm.rules[rule.index]),"validation":"bail|required","debounce":100}}),(_vm.rules[rule.index].condition_group[0].conditions[conditions.index].dataName)?[_c('FormulateInput',{attrs:{"options":_vm.operators(_vm.rules[rule.index].condition_group[0].conditions[conditions.index].dataName, _vm.rules[rule.index]),"type":"select","name":"operator","validation":"required","debounce":100}}),(
                            _vm.rules[rule.index]
                            &&
                            _vm.rules[rule.index].condition_group
                            &&
                            _vm.rules[rule.index].condition_group[0]
                             &&
                             _vm.rules[rule.index].condition_group[0].conditions
                             &&
                             _vm.rules[rule.index].condition_group[0].conditions[conditions.index]
                             &&
                             _vm.rules[rule.index].condition_group[0].conditions[conditions.index].dataName
                             &&
                             _vm.operatorNeedsValue(_vm.rules[rule.index].condition_group[0].conditions[conditions.index].operator))?[(_vm.valueInputType(_vm.rules[rule.index].condition_group[0].conditions[conditions.index].dataName) === 'number')?[_c('FormulateInput',{attrs:{"type":"decimal","inputmode":"decimal","help":"waarde","name":"value","validation":[['number'],['required']],"debounce":100}})]:[_c('FormulateInput',{attrs:{"type":"select","help":"waarde","name":"value","options":_vm.getElementValues(_vm.rules[rule.index].condition_group[0].conditions[conditions.index].dataName, _vm.rules[rule.index]),"validation":"bail|required","debounce":100}})]]:_vm._e()]:_vm._e()]:_vm._e()]}}],null,true)}),_c('FormulateInput',{attrs:{"label":"Welke condities moeten waar zijn?","options":{'AND': 'Alle condities (AND)', 'OR': 'Één conditie (OR)'},"type":"select","name":"method","validation":"required","debounce":100}})],1)],1),_c('v-col',[_c('FormulateInput',{key:rule.ruleForm,attrs:{"type":"group","name":"actions","repeatable":true,"add-label":"+ Actie toevoegen","label":"Dan"},scopedSlots:_vm._u([{key:"default",fn:function(actions){return [_c('FormulateInput',{attrs:{"options":{'show': 'Toon veld', 'hide': 'Verberg veld', 'visually_hidden': 'Verberg veld visueel', 'set': 'Waarde instellen'},"type":"select","name":"method","validation":"required","debounce":100}}),_c('FormulateInput',{attrs:{"type":"select","name":"dataName","placeholder":"Datanaam","options":_vm.getDataNamesOptionsAll(_vm.rules[rule.index]),"validation":"bail|required","debounce":100}}),(
                    _vm.rules[rule.index] &&
                    _vm.rules[rule.index].actions &&
                    _vm.rules[rule.index].actions[actions.index] &&
                    _vm.rules[rule.index].actions[actions.index].method === 'set')?[(
                            _vm.rules[rule.index] &&
                            _vm.rules[rule.index].actions &&
                            _vm.rules[rule.index].actions[actions.index] &&
                            ['select','radio'].includes(_vm.getDataNameElementType(_vm.rules[rule.index].actions[actions.index].dataName, _vm.rules[rule.index])))?[_c('FormulateInput',{attrs:{"type":"select","name":"value","options":_vm.getElementValues(_vm.rules[rule.index].actions[actions.index].dataName, _vm.rules[rule.index]),"validation":"bail|required","help":"Waarde","debounce":100}})]:[_c('FormulateInput',{attrs:{"type":"text","name":"value","validation":"bail|required","help":"Waarde. Dit veld ondersteund tokens.","debounce":100}})]]:_vm._e()]}}],null,true)})],1)],1)],1)],1)],1)]}}])}),_c('v-footer',{staticClass:"eform-footer-transparant py-2",attrs:{"padless":"","fixed":"","inset":"","app":""}},[_c('v-container',{attrs:{"fluid":""}},[_c('FormulateInput',{attrs:{"type":"submit","input-class":"v-btn v-size--default success","disabled":_vm.isSaving,"label":_vm.isSaving ? 'Laden...' : 'Regels opslaan'}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }