<template>

  <v-card class="elevation-1 login-wrapper">
    <v-card-title>Login</v-card-title>
    <v-card-text>

      <div class="login">
        <FormulateForm #default="{ isLoading }" @submit="submit">
          <v-row>
            <v-col class="mt-4">
              <FormulateInput
                  name="email"
                  type="text"
                  label="E-mailadres"
                  autocomplete="username"
                  validation="bail|required|email"
                  v-model="username" />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="mb-4">
              <FormulateInput
                  name="password"
                  type="password"
                  label="Wachtwoord"
                  autocomplete="current-password"
                  validation="bail|required"
                  v-model="password" />
            </v-col>
          </v-row>

          <FormulateInput
              type="submit"
              input-class="v-btn v-size--default success"
              :disabled="isLoading"
              :label="isLoading ? 'Laden...' : 'Inloggen'"
          />
          <a :href="passwordForgotUrl" target="_blank">Wachtwoord vergeten?</a>
        </FormulateForm>

        <p class="text-caption mt-5 mb-0 text-center">v{{ appVersion }}</p>

      </div>

    </v-card-text>
  </v-card>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import userHelpers from "@/mixins/userHelpers";
import store from "@/plugins/store";

export default {
  name: "Login",
  mixins: [
    userHelpers,
  ],
  components: {},
  data() {
    return {
      passwordForgotUrl: process.env.VUE_APP_API_BASEURL+'user/password?destination=user/password_reset_link_sent',
      username: "",
      password: "",
      appVersion: process.env.VUE_APP_VERSION,
    };
  },
  computed: {
    ...mapGetters({User: "StateUser"}),
  },
  methods: {
    ...mapActions(["LogIn"]),
    async submit() {

      try {
        let responseLogin = await store.dispatch('LogIn', {
          username: this.username,
          password: this.password
        })

        if (responseLogin.status === true) {
          // Add user data to rollbar so we can identify the user when an error occures.
          this.$rollbar.configure({
            payload: {
              person: {
                id: this.User.id, // required
                username: this.username
              }
            }
          });
          // Go to the user his dashboard
          this.$router.push({name: store.getters.userDashboardRouteName});
        }
        else {
          this.$toast.error(responseLogin.message);
        }
      }
      catch (error) {
        console.log(error);
        this.$toast.error("Er is iets mis gegaan tijdens het inloggen.");
      }

    },
  },
};
</script>

<style lang="scss" scoped>
.login-wrapper {
  max-width: 400px;
  margin: 10vh auto;
}
.formulate-input .formulate-input-element {
 max-width: none;
}
</style>