<template>
  <div :key="$route.params.formId">

    <template v-if="loading">
      <v-skeleton-loader type="heading"></v-skeleton-loader>
      <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
    </template>
    <div v-if="!loading">

      <eform-tokens v-if="myForm.schema.length" :tokens="this.getEformTokensFilterExclude(myForm.schema, ['subform_metadata'])" class="mr-12"></eform-tokens>

      <FormulateForm v-model="myForm" @submit="submitForm" @failed-validation="onValidationErrors">

        <FormulateInput
            type="group"
            name="email_settings"
            help="Voor elke verschillende email kunt u een notificatie optie toevoegen. Deze hebben eigen ontvangers, verzenders, en inhoud."
            :repeatable="true"
            label="Email notificaties"
            add-label="+ Email notificatie toevoegen"
            v-on:repeatableAdded="addedEmail"
            #default="{ index }"
            class="email-panels"
        >

          <v-expansion-panels>
            <v-expansion-panel :expand="true" class="email-panel">
              <v-expansion-panel-header v-slot="{ open }">
                <v-row no-gutters>
                  <v-col cols="3">
                    E-mail
                  </v-col>
                  <v-col cols="7" class="text--secondary">
                    <v-row no-gutters v-if="!open">
                      <template v-if="email_settings[index]">
                        <v-col>
                          Onderwerp: {{ email_settings[index].email_subject }}
                        </v-col>
                        <v-col>
                          Van: {{ email_settings[index].from_name }}
                        </v-col>
                      </template>
                    </v-row>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>

                <v-row>
                  <v-col>
                    <v-row>

                      <v-col>
                        <FormulateInput
                            label="Aan e-mailadres"
                            name="to_addresses"
                            type="group"
                            :repeatable="true"
                            add-label="+ Ontvanger toevoegen"
                            validation="required"
                        >
                          <FormulateInput
                              type="text"
                              name="to_address"
                              validation="^required|emailOrToken"
                              :element-class="['no-label']"
                              :debounce="100"
                          />
                        </FormulateInput>
                      </v-col>
                      <v-col>
                        <FormulateInput
                            label="CC e-mailadres"
                            name="cc_addresses"
                            type="group"
                            :repeatable="true"
                            add-label="+ CC adres toevoegen"
                            validation="optional"
                        >
                          <FormulateInput
                              type="text"
                              name="cc_address"
                              validation="optional|emailOrToken"
                              :debounce="100"
                          />
                        </FormulateInput>
                      </v-col>
                      <v-col>
                        <FormulateInput
                            label="BCC e-mailadres"
                            name="bcc_addresses"
                            type="group"
                            :repeatable="true"
                            add-label="+ BCC adres toevoegen"
                            validation="optional"
                        >
                          <FormulateInput
                              type="text"
                              name="bcc_address"
                              validation="optional|emailOrToken"
                              :debounce="100"
                          />
                        </FormulateInput>
                      </v-col>
                    </v-row>

                    <FormulateInput
                        label="Van (naam)"
                        type="text"
                        name="from_name"
                        validation="required"
                        class="mt-6"
                        :debounce="100"
                    />

                    <FormulateInput
                        label="Onderwerp"
                        type="text"
                        name="email_subject"
                        validation="required"
                        :debounce="100"
                    />


                    <FormulateInput
                        :options="{'auto': 'Automatisch', 'manual': 'Handmatig'}"
                        name="email_content_mode"
                        type="select"
                        label="Emailinhoud"
                    />


                    <div class="hidden" v-if="email_settings[index] && email_settings[index].hasOwnProperty('email_content_mode') && email_settings[index].email_content_mode === 'manual'">
                      <FormulateInput
                          label="Emailinhoud handmatig"
                          type="ckeditor"
                          name="email_content"
                          validation="required"
                          keep-model-data
                          :debounce="100"
                      />
                    </div>
                    <template v-else>
                      <p><strong>Emailinhoud</strong></p>
                      <div class="email-content-auto-preview mb-7">
                        <div v-html="email_html_template"></div>
                      </div>
                    </template>


                    <v-card outlined class="pa-5 mb-5">
                      <FormulateInput
                          label="Bijlages toevoegen"
                          type="checkbox"
                          name="email_add_attachments"
                      />
                    </v-card>

                    <v-card outlined class="pa-5 mb-5">
                      <FormulateInput
                          label="Alle e-mail styling weghalen"
                          help="Het e-formapp logo wordt niet meer getoond in deze e-mail en de e-mail wordt niet meer gecentreerd op een witte achtergrond geplaatst."
                          type="checkbox"
                          name="email_no_styling"
                      />
                    </v-card>

                    <v-card outlined class="pa-5 mb-5">
                      <FormulateInput
                          label="Registratie toevoegen als PDF bijlage"
                          help="Voeg een PDF bijlage toe met daarin de gehele registratie."
                          type="checkbox"
                          name="email_add_attachment_registration_pdf"
                      />

                      <FormulateInput
                        label="Bestandsnaam registratie PDF"
                        v-if="email_settings[index] && email_settings[index].hasOwnProperty('email_add_attachment_registration_pdf') && email_settings[index].email_add_attachment_registration_pdf === true"
                        type="text"
                        name="email_add_attachment_registration_pdf_file_name"
                        value="Registratie"
                        />
                    </v-card>


                    <v-card v-if="!clientModuleWordEnabled" outlined class="pa-5 d-flex" @click="showPremiumModal">
                      <v-chip v-if="!clientModuleWordEnabled" color="primary" class="mt-2 mr-4">
                        <v-icon>mdi-lock</v-icon>
                      </v-chip>
                      <FormulateInput
                          label="Verstuur een PDF of Microsoft Word document als bijlage"
                          help="U kunt een Word document uploaden met daarin tokens. De tokens worden automatisch vervangen door de ingevulde waardes in de registratie."
                          type="checkbox"
                          name="email_send_word_attachment"
                          :disabled="true"
                      />
                    </v-card>
                    <v-card v-if="clientModuleWordEnabled" outlined class="pa-5">
                      <FormulateInput
                          label="Verstuur een PDF of Microsoft Word document als bijlage"
                          help="U kunt een Word document uploaden met daarin tokens. De tokens worden automatisch vervangen door de ingevulde waardes in de registratie."
                          type="checkbox"
                          name="email_send_word_attachment"
                      />
                      <template v-if="email_settings[index] && email_settings[index].hasOwnProperty('email_send_word_attachment') && email_settings[index].email_send_word_attachment === true">

                        <v-row>
                          <v-col>
                            <p class="my-0 font-weight-bold">Word document <span class="red--text">*</span></p>
                            <template v-if="Array.isArray(email_settings[index].word_upload)">
                              <p class="my-0">
                                <a :href="email_settings[index].word_upload[0][0].url">{{ email_settings[index].word_upload[0][0].name }}</a>
                                <v-btn
                                    class="ml-2 py-0"
                                    small
                                    depressed
                                    color="error"
                                    outlined
                                    v-on:click="removeWordFile(index)"
                                >
                                  Verwijderen
                                </v-btn>
                              </p>
                            </template>
                            <template v-else>
                              <FormulateInput
                                  type="file"
                                  name="word_upload"
                                  help="Upload een .docx bestand met daarin de tokens die vervangen moeten worden."
                                  validation="required|mime:application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                  upload-url="/api/file-upload/email-word"
                                  @file-upload-complete="emailWordFileUploaded"
                                  :validation-messages="{
                              'mime': 'Er kunnen alleen Word (.docx) bestanden worden geupload'
                            }"
                              />
                            </template>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col>
                            <FormulateInput
                                label="Bestandsnaam"
                                help="Dit veld ondersteund tokens. Alleen letters a-z, cijfers, underscore _ en middenstreepje - karakters zijn toegestaan."
                                type="text"
                                name="word_filename"
                                validation="bail|required"
                                :debounce="100"
                            />
                          </v-col>
                          <v-col>
                            <FormulateInput
                                name="send_as_filetype"
                                :options="{pdf: 'PDF', docx: 'Microsoft Word'}"
                                type="select"
                                placeholder="Select an option"
                                label="Bestandstype"
                                validation="required"
                                value="pdf"
                            />
                          </v-col>
                        </v-row>

                        <v-expansion-panels :accordion="true" class="mt-4">
                          <v-expansion-panel>
                            <v-expansion-panel-header>Styling opties</v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <FormulateInput
                                  type="group"
                                  name="styling_options">
                                <FormulateInput
                                    name="word_document_default_font"
                                    :options="['Arial', 'Verdana', 'Tahoma', 'Trebuchet MS', 'Times New Roman', 'Georgia', 'Garamond', 'Courier New', 'Brush Script MT']"
                                    type="select"
                                    placeholder="Select an option"
                                    label="Standaard lettertype"
                                    validation="required"
                                    value="Arial"
                                />
                                <FormulateInput
                                    v-for="stylingToken in tokenStylingOptions"
                                    :key="stylingToken.elementName"
                                    type="group"
                                    :name="stylingToken.elementName">
                                  <FormulateInput
                                      :label="stylingToken.label"
                                      :key="stylingToken.elementName"
                                      type="checkbox"
                                      name="checked"
                                  />

                                  <template v-if="
                                email_settings[index].styling_options &&
                                Array.isArray(email_settings[index].styling_options) &&
                                email_settings[index].styling_options[0] &&
                                email_settings[index].styling_options[0].hasOwnProperty(stylingToken.elementName) &&
                                email_settings[index].styling_options[0][stylingToken.elementName][0] &&
                                email_settings[index].styling_options[0][stylingToken.elementName][0].hasOwnProperty('checked') &&
                                email_settings[index].styling_options[0][stylingToken.elementName][0].checked === true

    ">
                                    <v-row v-for="elementOption in getElementByName(myForm.schema, stylingToken.elementName).options" :key="elementOption.value">
                                      <v-col>
                                        <FormulateInput
                                            type="color"
                                            :name="elementOption.value"
                                            :label="elementOption.label"
                                            :key="elementOption.value"
                                            placeholder="Sample color placeholder"
                                            validation="required"
                                            value="#000000"
                                            error-behavior="live"
                                            :debounce="100"
                                        />
                                      </v-col>
                                    </v-row>
                                  </template>
                                </FormulateInput>
                              </FormulateInput>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>

                        <v-expansion-panels :accordion="true" class="mt-4">
                          <v-expansion-panel>
                            <v-expansion-panel-header>Voorbeeld documenten</v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <ul>
                                <li>
                                  <p><strong>Voorwaardelijke tokens:</strong> <a :href="wordExampleConditionalTokens">Download Word template</a><br>
                                    <span class="text-caption">Met voorwaardelijke tokens kunt u tekst of afbeeldingen wel of niet tonen wanneer er een optie is aangevinkt of uitgevinkt. Deze elementen ondersteunen voorwaardelijke tokens: <v-chip small class="background-color">Checkbox</v-chip> <v-chip small class="background-color">Checkboxes</v-chip> <v-chip small class="background-color">Meerkeuzevraag</v-chip> <v-chip small class="background-color">Pulldown</v-chip></span></p>
                                </li>
                              </ul>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>

                      </template>
                    </v-card>


                  </v-col>
                </v-row>

              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>


        </FormulateInput>


        <v-footer padless fixed inset app class="eform-footer-transparant py-2">
          <v-container fluid>
            <FormulateInput
                type="submit"
                input-class="v-btn v-size--default primary"
                :disabled="isSaving"
                :label="isSaving ? 'Laden...' : 'Email instellingen opslaan'"
            />
          </v-container>
        </v-footer>
      </FormulateForm>
    </div>

    <premium-wall :client_uuid="currentClientUUID" :module="premiumModule" :shown="premiumModalShown" @close="premiumModalShown = false"></premium-wall>

  </div>
</template>

<script>

import axios from "axios";
import EformTokens from "@/components/EformTokens";
// import EformFormulatePrefixSuffix from '@/components/EformFormulatePrefixSuffix';
import formulateComplexFileData from "@/mixins/formulateComplexFileData";
import eformHelpers from "@/mixins/eformHelpers";
import clientHelpers from "@/mixins/clientHelpers";
import premiumWall from "@/components/PremiumWall";

export default {
  name: "FormEmails",
  components: {
    EformTokens,
    premiumWall,
    // EformFormulatePrefixSuffix
  },
  mixins: [
    formulateComplexFileData,
    eformHelpers,
    clientHelpers,
  ],
  data() {
    return {
      myForm: null,
      tokenStylingOptions: null,
      loading: true,
      isSaving: false,
      form_id: this.$route.params.form_id,
      unsavedChanges: true,
      api_base_url: process.env.VUE_APP_API_BASEURL,
      premiumModule: 'word',
      premiumModalShown: false,
    }
  },
  computed: {
    showEmailAddressTokenWarningComputed: function() {
      return (this.myForm.settings && this.myForm.settings.guestAccess && this.myForm.settings.guestAccess === true);
    },
    email_settings: {
      get() {
        if (this.myForm && this.myForm.email_settings) {
          return this.myForm.email_settings;
        }
        return [];
      },
      set(values) {
        this.myForm.email_settings = values;
      }
    },
    email_html_template: function () {
      return this.myForm.email_html_template
    },
    wordExampleConditionalTokens() {
      return this.api_base_url+'modules/custom/eform/examples/example-word-conditional-tokens.docx'
    },
    currentClientUUID() {
      return this.$store.getters.CurrentClientUUID || "" // Fallback to empty string if no set
    },
  },
  async mounted () {
    // Get form from API
    await this.getDataFromApi()
    await this.loadClient(this.myForm.client_id)

    this.setTokenStylingOptions();
  },
  methods: {
    setTokenStylingOptions() {
      this.tokenStylingOptions = this.getTokensFromSchemaFilterInclude(this.myForm.schema, ['radio', 'select']);
    },
    removeWordFile(index) {
      this.email_settings[index].word_upload = null;
    },
    async getDataFromApi () {
      this.loading = true
      // Fetch results form the API
      await axios
          .get('api/form/'+this.form_id)
          .then(response => {
            this.myForm = response.data
            this.loading = false;

            // Vue Formulate bugfix: Remove the first item when it's empty.
            this.$nextTick(function(){
              if (!this.email_settings.length) {
                this.email_settings = []
              }
            })

          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => this.loading = false)
    },
    async addedEmail(values) {
      await this.$nextTick()
      // Get last item in the array
      let addedItem = values.slice(-1)[0]
      // Remove the first item in the repeatable Vue Formulate group 'cc' and 'bcc'. We don't want an empty first value.
      addedItem.cc_addresses = [];
      addedItem.bcc_addresses = [];
    },
    // Send the form to the API endpoint
    async submitForm(formValues) {
      let $vm = this
      this.isSaving = true;

      // Bugfix: Convert the Vue Formulate complex file upload inside repeatable groups into simple data.
      for (const email_setting of formValues.email_settings){
        // Convert the complex file data
        email_setting.word_upload = this.convertComplexFileData(email_setting.word_upload)
      }

      // Save form
      axios.patch('api/form/'+this.form_id, formValues)
          .then(async response => {
            this.myForm = response.data
            $vm.$toast.success("Email instellingen opgeslagen");
            // Reload the page, otherwise the new word upload doesn't show up
            await this.getDataFromApi()
            // Update the form metadata. This will make sure the Toast message will show up with the text "Word files are processing.."
            this.$emit('updateMetadata')
          })
          .catch(function (error) {
            $vm.$toast.error("Email instellingen konden niet worden aangepast.");
            // handle error
            console.log(error);
          })
          .finally(function() {
                $vm.isSaving = false
              }
          )

    },
    async onValidationErrors() {
      this.$toast.error("Controleer het formulier. Sommige velden zijn niet of verkeerd ingevuld.");
    },
    emailWordFileUploaded(file) {
      if (file.path === false) {
        // Retry after 1 second
        setTimeout(() => this.emailWordFileUploaded(file), 500);
      }
      else {
        // Get the file ID
        let file_id = file.path[0].id
        // Start processing the file
        axios.get('/api/process-word-file/'+this.form_id+'/'+file_id)
      }
    },
    showPremiumModal() {
      if (!this.clientModuleWordEnabled) {
        this.premiumModalShown = true
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.unsavedChanges === true) {
      const answer = window.confirm('Wilt u deze pagina verlaten? Indien u wijzigingen heeft aangebracht gaan deze verloren.')
      if (answer) {
        next()
        return
      } else {
        next(false)
        return
      }
    }
    next()
  }
}
</script>

<style>
.email-panels.formulate-input[data-classification=group] > .formulate-input-wrapper > [data-is-repeatable] > .formulate-input-grouping > .formulate-input-group-repeatable {
  padding: 0 3em 0 0;
}
.email-panels.formulate-input[data-classification=group] > .formulate-input-wrapper > [data-is-repeatable] > .formulate-input-group-add-more {
  margin-left: -1em;
}
.email-content-auto-preview {
  box-shadow: rgba(0, 0, 0, 0.1) 3px 3px 6px 0 inset;
  border: 1px solid #eee;
  padding: 10px;
  max-height: 350px;
  overflow-y: scroll;
}
</style>