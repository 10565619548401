<template>
  <v-app>

    <v-progress-linear
        indeterminate
        v-if="loading"
        class="axiosLoader"
    ></v-progress-linear>

    <div v-if="isAdmin" :class="'environment-message environment-'+environment">{{ environment.toUpperCase() }}</div>

    <nav-bar v-if="isAuthenticated">
    </nav-bar>

    <v-main :style="{background: $vuetify.theme.themes[theme].background}">
      <v-alert
          class="ma-4 mx-4 mx-md-8"
          v-if="appOnline === false"
          dense
          prominent
          type="warning"
      >
        U bent momenteel offline. U kunt geen formulieren versturen en hebt mogelijk niet de nieuwste versie van formulieren beschikbaar. Ga niet uitloggen als u nog opgeslagen registraties heeft die alleen op dit apparaat staan.
      </v-alert>

      <v-alert
          class="ma-4 mx-4 mx-md-8"
          v-if="serverNotification && serverNotification.message"
          :type="serverNotification.type"
      >
        {{ serverNotification.message }}
      </v-alert>

      <page-title></page-title>

      <div class="container-wrapper">
        <router-view></router-view>
      </div>

    </v-main>

    <v-snackbar bottom center :value="updateExists" :timeout="-1" color="primary">
      <p>
        <span class="font-weight-bold">Nieuwe update beschikbaar.</span><br>
        Sla uw werk op voordat u update.
      </p>
      <v-btn outlined @click="refreshApp">
        Nu updaten
      </v-btn>
    </v-snackbar>

    <terms-and-conditions-wall
        v-for="(item, i) in termsAndConditionsRequired"
        :key="i"
        :client_uuid="item"
    >
    </terms-and-conditions-wall>

  </v-app>
</template>

<script>
import NavBar from "./components/NavBar";
import store from './plugins/store';
import update from '@/plugins/update'
import PageTitle from '@/components/Pagetitle'
import userHelpers from "@/mixins/userHelpers";
import {mapGetters,mapState} from "vuex";
import _ from "lodash";
import TermsAndConditionsWall from "@/components/TermsAndConditionsWall";

export default {
  name: 'App',
  components: {
    TermsAndConditionsWall,
    NavBar,
    PageTitle,
  },
  mixins: [update, userHelpers],
  created: function() {
    this.pollUserInfo()
  },
  methods: {
    pollUserInfo () {
      this.userInfoPolling = setInterval(() => {
        // Fetch user and client info
        if (store.getters.isAuthenticated) {
          this.$store.dispatch('UserInfo');
        }
      }, 60000)
    }
  },
  data () {
    return {
      environment: process.env.VUE_APP_ENVIRONMENT,
      userInfoPolling: null,
    }
  },
  computed: {
    ...mapGetters({User: "StateUser"}),
    ...mapState('loader', ['loading']),
    appOnline() {
      return store.getters.appOnline;
    },
    isAuthenticated() {
      return store.getters.isAuthenticated;
    },
    isAdmin() {
      return store.getters.userIsAdmin;
    },
    termsAndConditionsRequired() {
      return store.getters.termsAndConditionsRequired;
    },
    serverNotification() {
      return store.getters.serverNotification ? store.getters.serverNotification : false;
    },
    theme(){
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    }
  },
  async mounted() {
    // We need to wait till storage is restored because committing new changes.
    await store.restored

    // Force logout users when they still have old oauth access key.
    // @TODO: Remove this after a couple of weeks/months when all users are migrated.
    if (store.getters.isAuthenticated && _.has(this.$store.state.auth, 'access_token')) {
      this.$router.push({ name: 'logout'})
    }

    // Reset loader
    store.commit('loader/reset');
    // Set online status
    store.commit('setOnlineStatus', navigator.onLine);

    const onlineHandler = () => {
      console.log('Went online');
      this.$emit('online')
      store.commit('setOnlineStatus', true);
    }

    const offlineHandler = () => {
      console.log('App down, I repeat, App down')
      this.$emit('offline')
      store.commit('setOnlineStatus', false);
    }

    window.addEventListener('online',  onlineHandler)
    window.addEventListener('offline',  offlineHandler)



    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('online', onlineHandler)
      window.removeEventListener('offline', offlineHandler)
    })
  },
  beforeDestroy () {
    clearInterval(this.userInfoPolling)
  },
};
</script>

<style lang="scss">

// Import Formulate styles
@import '../node_modules/@braid/vue-formulate/themes/snow/snow.scss';
// Override default theme with our own styles
@import 'styles/formulate.scss';

.environment-message {
  color: white;
  font-size: 11px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  height: 15px;
  text-align: center;
  width: 100%;

  &.environment-development {
    display: none;
  }
  &.environment-staging {
    background: var(--v-warning-lighten2);
  }
  &.environment-production {
    background: var(--v-error-base);
  }
}

.axiosLoader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
}

// Add subtle shadow to the footer
footer.eform-footer-transparant {
  border-top: 1px solid map-get($material-theme, 'dividers') !important;
  z-index: 10; // To make sure it's above the Vue formulate file upload masks

  &.theme--light {
    background-color: rgba(255, 255, 255, 0.95) !important;

    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      -webkit-backdrop-filter: saturate(180%) blur(20px);
      backdrop-filter: saturate(180%) blur(20px);
      background-color: rgba(255, 255, 255, 0.6) !important;
    }
  }
}

.v-tab {
 text-transform: none;
}

.Vue-Toastification__container {
  .Vue-Toastification__toast--success {
    background-color: var(--v-secondary-base);
  }
  .Vue-Toastification__toast--error {
    background-color: var(--v-error-base);
  }
  .Vue-Toastification__toast--warning {
    background-color: var(--v-warning-base);
  }
}

.background-color {
  background-color: var(--v-background-base) !important;
}

.v-card__text, .v-card__title {
  word-break: break-word; // Break on word instead of letter on all v-card elements
}

</style>
