<template>
  <div>

    <div v-if="loading">
      <v-skeleton-loader type="heading"></v-skeleton-loader>
      <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
    </div>
    <div v-else>
      <FormulateForm v-model="myForm" @submit="submitForm" @failed-validation="onValidationErrors">
        <FormulateInput
            type="group"
            name="webhook_integrations"
            help="Naar elk van deze integraties wordt een kopie van het formulier met de registratie gestuurd"
            :repeatable="true"
            label="Webhook integraties"
            add-label="+ Integratie toevoegen"
            validation="optional"
            #default="integration"
        >

          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header v-slot="{ open }">
                <v-row no-gutters>
                  <v-col cols="3">
                    Integratie
                  </v-col>
                  <v-col cols="7" class="text--secondary">
                    <v-row no-gutters v-if="!open">
                      <v-col>
                        <template v-if="webhook_integrations[integration.index]">
                          {{ webhook_integrations[integration.index].ruleName }}
                        </template>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>

                <v-row>
                  <v-col>
                    <FormulateInput
                        label="Naam"
                        type="text"
                        name="hook_name"
                        validation="required"
                    />
                    <FormulateInput
                        :options="{'post-to-url': 'POST formulier naar URL', 'sia-signal-create': 'SIA - Signaal aanmaken', 'schouten-merge-registrations': 'Schouten - Formulieren samenvoegen'}"
                        name="hook_type"
                        type="select"
                        label="Integratie type"
                        validation="required"
                    />
                    <template v-if="webhook_integrations[integration.index] && webhook_integrations[integration.index].hasOwnProperty('hook_type') && webhook_integrations[integration.index].hook_type === 'post-to-url'">
                      <FormulateInput
                          type="url"
                          help="Volledige webhook url incl. https://"
                          label="Integratie url"
                          name="hook_url"
                          validation="^required|url"
                      />
                    </template>
                    <template v-if="webhook_integrations[integration.index] && webhook_integrations[integration.index].hasOwnProperty('hook_type') && webhook_integrations[integration.index].hook_type === 'schouten-merge-registrations'">
                      <FormulateInput
                          type="email"
                          help="Naar welk e-mailadres moet de samengevoegde registratie verstuurd worden? Dit veld overschijft het $email_to$ token. Dit token kan gebruikt worden in het 'aan' veld als je een e-mail aanmaakt."
                          label="E-mailadres van samengevoegde registratie"
                          name="merged_registration_email"
                          validation="required|email"
                      />
                    </template>
                  </v-col>
                </v-row>

              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

        </FormulateInput>

        <v-footer padless fixed inset app class="eform-footer-transparant py-2">
          <v-container fluid>
            <FormulateInput
                type="submit"
                input-class="v-btn v-size--default success"
                :disabled="isSaving"
                :label="isSaving ? 'Laden...' : 'Integraties opslaan'"
            />
          </v-container>
        </v-footer>
      </FormulateForm>
    </div>

  </div>
</template>

<script>

import axios from "axios";

export default {
  name: "FormIntegrations",
  data() {
    return {
      loading: true,
      isSaving: false,
      myForm: null,
      form_id: this.$route.params.form_id,
      unsavedChanges: true,
    }
  },
  computed: {
    webhook_integrations: {
      get() {
        if (this.myForm && this.myForm.webhook_integrations) {
          return this.myForm.webhook_integrations;
        }
        return [];
      },
      set(values) {
        this.myForm.webhook_integrations = values;
      }
    },
  },
  mounted () {
    // Get form from API
    this.getDataFromApi()
  },
  methods: {
    // Send the form to the API endpoint
    async submitForm(formValues) {
      let $vm = this
      this.isSaving = true;

      // Save form
      axios.patch('api/form/'+this.form_id, formValues)
          .then(response => {
            this.myForm = response.data
            $vm.$toast.success("Integraties zijn opgeslagen");
          })
          .catch(function (error) {
            $vm.$toast.error("Integraties konden niet worden aangepast.");
            // handle error
            console.log(error);
          })
          .finally(function() {
                $vm.isSaving = false
              }
          )

    },
    getDataFromApi () {
      this.loading = true
      // Fetch results form the API
      axios
          .get('api/form/'+this.form_id)
          .then(response => {
            this.myForm = response.data
            this.loading = false;

            // Vue Formulate bugfix: Remove the first item when it's empty.
            this.$nextTick(function(){
              if (!this.webhook_integrations.length) {
                this.webhook_integrations = []
              }
            })

          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => this.loading = false)
    },
    async onValidationErrors() {
      this.$toast.error("Controleer het formulier. Sommige velden zijn niet of verkeerd ingevuld.");
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.unsavedChanges) {
      const answer = window.confirm('Wilt u deze pagina verlaten? Indien u wijzigingen heeft aangebracht gaan deze verloren.')
      if (answer) {
        next()
        return
      } else {
        next(false)
        return
      }
    }
    next()
  }
}
</script>

<style scoped>

</style>