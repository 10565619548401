<template>
  <div :key="$route.params.form_id">

    <v-row v-if="loading">
      <v-col cols="4">
        <v-sheet class="pa-4 rounded-lg">
          <v-skeleton-loader type="table-heading, divider, text@3"></v-skeleton-loader>
        </v-sheet>
        <v-sheet class="pa-4 rounded-lg mt-4">
          <v-skeleton-loader type="table-heading, divider, text@3"></v-skeleton-loader>
        </v-sheet>
      </v-col>
      <v-col cols="8">
        <v-sheet class="pa-4 rounded-lg">
          <v-skeleton-loader type="sentences, text@3, image"></v-skeleton-loader>
        </v-sheet>
      </v-col>
    </v-row>

    <div class="row" v-if="!loading">
      <div class="col-md-4">

        <v-sheet class="pa-4 rounded-lg mb-4">
          <div v-if="myForm.published_at">
            <h3>Gepubliceerd op {{ myForm.published_at|formatDate }}</h3>
            <v-switch v-model="myForm.published_at" :loading="isSaving" @change="unpublishForm()" label="Gepubliceerd" color="primary" v-if="userHasPermission('eform manage form unpublish')"></v-switch>
          </div>
          <div v-else>
            <h3>Niet gepubliceerd</h3>
            <v-switch v-model="myForm.published_at" :loading="isSaving" @change="publishForm()" label="Gepubliceerd" color="primary" v-if="userHasPermission('eform manage form publish')"></v-switch>
          </div>

          <div>
            Laatst gewijzigd door {{ myForm.user.username }} op {{ myForm.updated_at|formatDate }}
          </div>

          <form-revisions :form_id="myForm.form_id" v-if="userHasPermission('eform manage form revisions view')"></form-revisions>

        </v-sheet>

        <form-users
            :my-form="myForm"
            @updateForm="updateForm($event)"
            v-if="userHasPermission('eform manage form users')"
        ></form-users>

        <v-sheet class="pa-4 rounded-lg mb-4" v-if="userHasPermission('eform manage form name')">
          <h3>Aanpassen</h3>
          <FormulateInput
              label="Formuliernaam"
              type="text"
              v-model="myForm.form_name" />
          <FormulateInput
              label="Map"
              type="select"
              required
              :options="folder_options"
              v-model="myForm.folder_id" />

          <FormulateInput
              type="ckeditor"
              editor-preset="simple"
              label="Registratie samenvatting"
              help="Dit veld ondersteund tokens. Deze samenvatting wordt getoond bij opgeslagen registraties."
              validation="optional"
              :debounce="100"
              v-model="myForm.form_summary"
              error-behavior="live" />

          <eform-tokens
              class="mb-4"
              v-if="myForm.schema.length"
              :tokens="getTokensFromSchemaFilterInclude(myForm.schema, ['text', 'textarea', 'number', 'email', 'date', 'time', 'datetime', 'checkbox_single', 'radio', 'select'])"
          ></eform-tokens>

          <v-btn
              depressed
              color="primary"
              @click="submitForm"
              :disabled="isSaving"
              min-width="210"
          >
            {{ isSaving ? 'Laden...' : 'Formulier aanpassen' }}
          </v-btn>
        </v-sheet>

        <v-sheet class="pa-4 rounded-lg mb-4" v-if="userHasPermission('eform manage form search')">
          <h3>Registraties doorzoeken</h3>
          <div>
            <v-switch v-model="myForm.searchEnabled" :loading="isSaving" @change="changeSearch()" label="Zoeken in/uitschakelen" color="primary">
            </v-switch>
            <v-alert
                class="mb-4"
                dense
                prominent
                text
                type="warning"
                v-if="myForm.searchEnabled"
            >Beveiligingswaarschuwing: Iedereen die toegang heeft tot dit formulier kan alle registraties bekijken.</v-alert>
          </div>
          <div class="my-2" v-if="myForm.searchEnabled">
            <v-btn
                depressed
                :disabled="isSaving"
                color="primary"
                @click="showSearchFormModal"
                min-width="210">
              Zoekinstellingen
            </v-btn>
            <v-dialog @click:outside="closeSearchFormModal" v-model="showSearchModal" max-width="800" scrollable>
              <v-card>
                <v-card-title>
                  Zoekinstellingen
                </v-card-title>
                <v-card-text>
                  <eform-tokens v-if="myForm.schema.length" :subform_schema="myForm.schema" :tokens="getEformTokensFilterExclude(this.schema, ['subform'])"></eform-tokens>
                  <FormulateInput
                      v-model="myForm.registrationNameToken"
                      type="textarea"
                      label="Registratie naam"
                      :value="computedRegistrationNameToken()"
                      help="De naam van de registratie wordt weergeven bij het doorzoeken van registraties. Deze kan tokens bevatten en helpt de gebruiker met het vinden van de juiste registraties"
                  />
                  <v-progress-circular
                      v-if="searchFormModalLoading"
                      indeterminate
                      color="primary"
                  ></v-progress-circular>
                  <FormulateInput
                      v-if="!searchFormModalLoading"
                      v-model="searchValues"
                      :options="searchFields"
                      type="checkbox"
                      label="Beschikbare zoekvelden"
                  />
                </v-card-text>
                <v-card-actions class="background-color">
                  <v-btn
                      color="secondary"
                      outlined
                      @click="closeSearchFormModal"
                  >
                    Sluit
                  </v-btn>
                </v-card-actions>

              </v-card>
            </v-dialog>
          </div>
        </v-sheet>

        <v-sheet class="pa-4 rounded-lg mb-4" v-if="userHasPermission('eform manage form edit')">
          <h3>Formulier voor niet-ingelogde gebruikers beschikbaar stellen</h3>
          <v-switch v-model="guestAccess" :loading="isSaving" @change="changeGuestAccess()" label="Formulier beschikbaar voor niet-ingelogde gebruikers" color="primary">
          </v-switch>
          <div v-if="guestAccess">
            <FormulateInput
                type="group"
                label="Publieke link voor het formulier">
              <code class="public-url">
                <template v-pre></template>{{ getFormGuestUrl() }}<template v-pre></template>
                <v-icon class="ml-1" small
                        v-clipboard:copy="getFormGuestUrl()"
                        v-clipboard:success="onCopySuccess"
                        v-clipboard:error="onCopyError"
                >mdi-clipboard-multiple-outline</v-icon>
              </code>
            </FormulateInput>
          </div>
        </v-sheet>

        <v-sheet class="pa-4 rounded-lg mb-4" v-if="userHasPermission('eform manage form edit')">
            <h3>Kopie van het formulier maken</h3>
          <template v-if="!this.loading">
            <template v-if="copyClientOptions.length > 1">
              <FormulateInput
                  type="checkbox"
                  v-model="copyToSameClient"
                  label="Kopie hoort bij dezelfde klant"
              />
              <template v-if="!copyToSameClient">
                <v-alert
                    color="warning"
                    dark
                    dense
                    elevation="2"
                >Let op: Bij een kopie naar een andere klant worden de map en de toegewezen gebruikers niet meegenomen!</v-alert>
                <FormulateInput
                    type="select"
                    v-model="copyNewClientId"
                    v-if="!copyToSameClient"
                    :options="copyClientOptions"
                />
              </template>
            </template>
            <v-btn
                class="my-2"
                depressed
                color="primary"
                @click="duplicateForm"
                min-width="210"
            >Formulier kopiëren</v-btn>
          </template>

        </v-sheet>


        <v-sheet class="pa-4 rounded-lg mb-4" v-if="userHasPermission('eform manage form edit')">
          <h3>Slecht internet modus</h3>
          <div>
            <v-switch v-model="myForm.bad_internet_mode" :loading="isSaving" @change="changeBadInternet()" label="Slecht internet modus" color="primary">
            </v-switch>
            <p class="caption">Als deze modus ingeschakeld is, dan worden afbeeldingen en bestanden op het apparaat opgeslagen en pas geupload wanneer het formulier verstuurd wordt. Deze modus is aan te raden als u op locatie foto's maakt en er een kans op slecht internet is.</p>
          </div>
        </v-sheet>


        <v-sheet class="pa-4 rounded-lg mt-4" v-if="userHasPermission('eform manage form delete')">
          <h3>Verwijder formulier</h3>
          <p>Verwijder het formulier inclusief alle registraties.</p>
          <template>
            <v-btn
                depressed
                color="error"
                class="white--text"
                :to="{ name: 'form_remove', params: {form_id: form_id} }"
                min-width="210"
            >
              Verwijder formulier
            </v-btn>
          </template>
        </v-sheet>
      </div>
      <div class="col-md-8">
        <div class="px-6">

          <v-sheet class="pa-4 mb-8 rounded-lg" v-if="myForm.form_type === 'workflow'">
            <h3>Formulier met workflow</h3>
            <ul class="text-caption text--secondary">
              <li>U kunt het formulier naar een andere fase verplaatsen door het element "Fase overgang" toe te voegen via het tabblad "Aanpassen".</li>
              <li>U kunt elementen indelen bij fases door naar "Regels" tabblad te gaan. Daar kunt u de conditie "Registratie fase" gebruiken om elementen te tonen/verbegen per fase.</li>
            </ul>
            <FormulateInput
                v-model="myForm.registration_phase"
                :options="{1: '1', 2: '2', 3: '3', 4: '4', 5: '5', 6: '6', 7: '7', 8: '8', 9: '9'}"
                type="select"
                validation="required"
                label="Toon formulier in fase"
                help="Bekijk hoe het formulier er uit ziet in de selecteerde fase."
            />

              <v-alert
                  v-if="!formSchemaHasStateTransitionElement"
                  dense
                  prominent
                  text
                  type="error"
              >
                Dit formulier heeft geen element van het type "Fase overgang". Voeg een element van het type "Fase overgang" toe om het formulier naar een andere fase te verplaatsen.
              </v-alert>

              <v-alert
                  v-if="myForm.registration_phase > 1 && formConditionalSchemaStateTransitionElementCount === 0"
                  dense
                  prominent
                  text
                  type="warning">
                Deze fase heeft geen element van het type "Fase overgang". Als het formulier in deze fase verstuurd wordt, dan wordt de registratie definitief verstuurd. Als u wilt dat de registratie naar een andere fase verplaatst wordt, voeg dan een element van het type "Fase overgang" toe.
              </v-alert>

              <v-alert
                  v-if="formConditionalSchemaStateTransitionElementCount > 1"
                  dense
                  prominent
                  text
                  type="warning">
                Deze fase heeft meerdere elementen van het type "Fase overgang". Dit is niet toegestaan. Zorg er voor dat er maar één element van het type "Fase overgang" in de fase aanwezig is.
              </v-alert>

          </v-sheet>

          <FormulateForm v-model="values" class="formulate-style-compact">

            <v-container>
              <v-row v-for="item in conditionalSchema" :key="item.uuid">
                <v-col class="pa-0">
                  <eform-element
                      :element="item"
                      :disabled-prop="setDisabled(item)"
                      v-show="isVisuallyShown(item)"
                      :is-admin-mode="false"
                      :values="values"
                      :schema="schema"
                  />

                </v-col>
              </v-row>
            </v-container>
          </FormulateForm>
          <p v-if="!myForm.schema.length">Er zijn nog geen velden toegevoegd aan dit formulier.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EformElement from "@/components/EformElement";
import axios from "axios";
import eformConditionals from "@/mixins/eformConditionals";
import userHelpers from "@/mixins/userHelpers";
import {mapGetters} from "vuex";
import eformHelpers from "../../mixins/eformHelpers";
import _ from "lodash";
import EformTokens from "../../components/EformTokens";
import { uuid } from 'vue-uuid';
import FormRevisions from "@/components/FormRevisions.vue";
import FormUsers from "@/views/forms/FormUsers.vue";

export default {
  name: "Form",
  components: {
    FormUsers,
    FormRevisions,
    EformTokens,
    EformElement,
  },
  mixins: [
    eformConditionals,
    userHelpers,
    eformHelpers,
  ],
  props: {
    myForm: null,
  },
  data() {
    return {
      loading: true,
      isSaving: false,
      searchFields: [],
      searchValues: [],
      searchFormModalLoading: true,
      showSearchModal: false,
      form_id: this.$route.params.form_id,
      showError: false,
      copyToSameClient: true,
      copyNewClientId: null,
      copyClientOptions: [],
      folder_options: {},
    }
  },
  computed: {
    ...mapGetters({User: "StateUser", CurrentClient: "CurrentClient"}),
    values: {
      get() {
        // Get the form values
        let values = this.myForm.values
        // Get all subform metadata
        let subformMetadata = this.getAllSubformMetadataValues();
        // Add the subform metadata to the values
        values = {
          ...values,
          ...subformMetadata,
        }
        return values;
      },
      async set(values) {
        this.myForm.values = values;
      }
    },
    schema: {
      get() {
        return this.myForm.schema;
      }
    },
    rules: {
      get() {
        return this.myForm.rules;
      }
    },
    guestAccess: {
      get() {
        if (this.myForm && this.myForm.settings && this.myForm.settings.guestAccess) {
          return this.myForm.settings.guestAccess;
        }
        return false;
      },
      set(values) {
        if (!this.myForm.settings) {
          this.myForm.settings = {};
        }

        this.$set(this.myForm.settings, 'guestAccess', values);
      }
    },
    guestID: {
      get() {
        if (this.myForm && this.myForm.settings && this.myForm.settings.guestID) {
          return this.myForm.settings.guestID;
        }

        return '';
      },
      set(values) {
        if (!this.myForm.settings) {
          this.myForm.settings = {};
        }

        this.$set(this.myForm.settings, 'guestID', values);
      }
    },
    /**
     * Count total number of elements in the schema of type 'stateTransition'
     */
    formConditionalSchemaStateTransitionElementCount() {
      return this.conditionalSchema.filter(item => item.elementType === 'stateTransition').length;
    },
    /**
     * Checks if the form schema has at least one element of type 'stateTransition'
     *
     * @returns {boolean}
     */
    formSchemaHasStateTransitionElement() {
      return this.schema.filter(item => item.elementType === 'stateTransition').length > 0;
    },
  },
  mounted () {
    // Get registration from API
    this.getDataFromApi();
    this.setFolderOptions();

    this.setClientOptions();

    // this.setSearchOptions();

  },

  methods: {
    getFormGuestUrl() {
      const resolved = this.$router.resolve({
        name: 'guest_form',
        params: {
          guest_id: this.guestID,
        },
      });

      let link =process.env.VUE_APP_BASEURL;
      link += resolved.href;

      return link;
    },
    setClientOptions() {
      const clientArray = [];
      if (this.User.clients) {
        for (let i = 0; i < this.User.clients.length; i++) {
          clientArray.push({'value': this.User.clients[i].id, 'label': this.User.clients[i].name});
        }
      }

      if (!_.isEmpty(this.myForm.client_id)) {
        this.copyNewClientId = this.myForm.client_id;
      }
      this.copyClientOptions = clientArray;
    },
    computedRegistrationNameToken() {
      if (this.myForm.registrationNameToken) {
        return this.myForm.registrationNameToken;
      }
      else {
        return this.defaultRegistrationName;
      }
    },
    changeSearch() {
      this.submitForm();
    },
    changeBadInternet() {
      this.submitForm();
    },
    changeGuestAccess() {
      if (_.has(this.myForm, 'settings') && _.has(this.myForm.settings, 'guestAccess') && this.myForm.settings.guestAccess === true) {
        this.guestID = uuid.v4();
      }

      this.submitForm();
    },
    async setSearchOptions() {
      this.searchFields = await this.getSearchableElementsFromSchema(this.schema, this.searchableFieldTypes);

      if (Object.prototype.hasOwnProperty.call(this.myForm, 'searchableFields') && _.isArray(this.myForm.searchableFields) && this.myForm.searchableFields.length > 0) {
        this.searchValues = this.myForm.searchableFields.map(a => a.value);
      }
    },
    async getSearchableElementsFromSchema(schema, filtered, prefix) {
      let elements = this.getElementsFromSchemaFiltered(schema, filtered);
      let options = [];

      for(let i = 0; i < elements.length; i++) {
        let element = elements[i];
        let name = element.name;
        let label = element.label;
        if (prefix) {
          name = prefix.name + "." + element.name;
          label = prefix.label + " > " + element.label
        }
        // Get subform values recursively
        if (element.elementType === 'subform') {
          let subformOptions = await this.getSearchableElementsFromSchema(element.subform.schema, filtered, {name: element.name, label: element.label})
          options = options.concat(subformOptions);
        }

        // Get subform map values from datasource
        else if (element.elementType === 'map') {
          let mapOptions = [];

          if (element.mapSettings && element.mapSettings.mapLayers[0] &&  element.mapSettings.mapLayers[0].datasource_id) {
            let mapItems = await this.getDatasourceOptionsFromApi( element.mapSettings.mapLayers[0].datasource_id);

            mapItems.forEach(function(mapOption) {
              mapOptions.push({
                // features.properties is needed to match the internal structure
                value: name + ".features.properties." + mapOption,
                label: label + " > " + mapOption,
              })
            });
          }
          options = options.concat(mapOptions);
        }
        // Get subform search values from datasource
        else if (element.elementType === 'search') {
          let searchOptions = [{
            value: name + ".name",
            label: label + " > Naam"
          }];
          if (element.searchSettings && element.searchSettings.datasourceId) {
            let searchItems = await this.getDatasourceOptionsFromApi(element.searchSettings.datasourceId);
            searchItems.forEach(function(searchOption) {
              searchOptions.push({
                // data key is needed to match the internal structure
                value: name + ".data." + searchOption,
                label: label + " > " + searchOption,
              })
            });
          }
          options = options.concat(searchOptions);
        }
        else {
          options.push({value: name, label: label})
        }
      }

      return options;
    },
    setDisabled(item) {
      return (item.elementType === 'file' || item.elementType === 'image' || item.elementType === 'linkedForm');
    },
    isVisuallyShown(item) {
      if (item.visuallyHidden) {
        return false;
      }
      return true;
    },
    async setFolderOptions() {
      let options = {};
      let response = await axios.get('/api/client/' + this.myForm.client_id + '/folders');
      if (response && response.status && response.status === 200) {
        response.data.forEach(function(item) {
          options[item.folder_id] = item.folder_name;
        });
      }

      this.folder_options = options;
    },
    getDataFromApi () {
      this.loading = true
      // Fetch results form the API
      axios
          .get('api/form/'+this.form_id)
          .then(response => {
            this.updateForm(response.data);
            this.loading = false;
          })
          .catch(error => {
            console.log(error)
            this.errored = true
          })
          .finally(() => this.loading = false)
    },
    async getDatasourceOptionsFromApi(datasource_id) {
      let returnValue = [];
      // Fetch results form the API
      await axios
          .get('api/datasource/'+datasource_id+'/tokens')
          .then(response => {
            returnValue = response.data;
          })
          .catch(error => {
            console.log(error)
          })

      return returnValue;
    },
    publishForm() {
      // Fetch new oauth tokens
      this.loading = true;
      axios.post('api/form/'+this.form_id+'/publish', [this.form_id])
          .then(response => {
            this.updateForm(response.data);
            this.loading = false
          })
          .catch(error => {
            console.log(error)
            this.errored = true
          })
          .finally(() => this.loading = false)
    },

    async unpublishForm() {
      this.loading = true;
      axios.post('api/form/'+this.form_id+'/unpublish', [this.form_id])
          .then(response => {
            this.updateForm(response.data);
            this.loading = false
          })
          .catch(error => {
            console.log(error)
            this.errored = true
          })
          .finally(() => this.loading = false)
    },

    async duplicateForm() {
      try {
        this.loading = true;
        let clientIdChanged = false;
        const oldFormName = _.clone(this.myForm.form_name);
        const oldForm = _.cloneDeep(this.myForm);

        // Always remove all assigned users and groups
        delete oldForm.users;

        if (!this.copyToSameClient && !_.isNull(this.copyNewClientId) && this.copyNewClientId !== oldForm.client_id) {
          oldForm.client_id = this.copyNewClientId
          clientIdChanged = true;
          delete oldForm.folder_id
        }

        const newFormId = await this.$store.dispatch('createDuplicateFromForm', {oldForm: oldForm});

        if (clientIdChanged) {
          await this.$store.commit('setCurrentClient', this.copyNewClientId)
          this.$toast.success('Kopie van formulier "'+oldFormName+'" is aangemaakt. U bent gewisseld van klant en doorgestuurd naar het nieuwe formulier.');
        }
        else {
          this.$toast.success('Kopie van formulier "'+oldFormName+'" is aangemaakt. U bent doorgestuurd naar het nieuwe formulier.');
        }

        await this.$router.push({name: 'form_view', params: {form_id: newFormId}});
      } catch (error) {
        this.$toast.error("Er kon geen kopie van het formulier worden gemaakt");
        console.log(error);
      }

      this.loading = false;

    },

    async submitForm() {
      try {
        this.isSaving = true;
        // Post form to API
        axios.patch('api/form/'+this.form_id, this.myForm)
            .then(response => {
              // Update the form
              this.$emit('updateForm', response.data)
              // Set flag
              this.isSaving = false
              // Show toast message
              this.$toast.success("Formuliernaam '" + this.myForm.form_name + "' is aangepast.");
            })
            .finally(() => this.isSaving = false)

      } catch (error) {
        this.$toast.error("Het formulier kon niet worden aangepast.");
        this.isSaving = false;
        console.log(error);
      }
    },

    updateForm(form) {
      // Update the form
      this.$emit('updateForm', form)
    },
    showSearchFormModal() {
      this.showSearchModal = true;

      this.searchFormModalLoading = true;

      this.setSearchOptions().then(() => this.searchFormModalLoading = false);

    },
    closeSearchFormModal() {
      // Save settings
      let searchableFields = [];

      if (this.searchValues.length > 0) {
        searchableFields = this.searchFields.filter(({value}) => this.searchValues.includes(value));
      }

      // For future: Only submit if the values actually changed (has to check myForm.registrationNameToken as well)
      this.myForm.searchableFields = searchableFields;
      this.submitForm();

      this.showSearchModal = false;
    },
    onCopySuccess() {
      let $vm = this;
      $vm.$toast.success("Gekopieerd");
    },
    onCopyError() {
      let $vm = this;
      $vm.$toast.error("Kon niet kopieren.");
    },
  }
}
</script>

<style scoped>
.public-url {
  word-break: break-all;
}
</style>