<template>
  <div>

    <v-container fluid class="px-8 mb-3">
      <v-row>
        <v-col>
          <h4>Credit verbruik per klant afgelopen 30 dagen</h4>
          <p class="text-caption mb-2">Alle credit verbruik van de afgelopen 30 dagen uitgesplits per klant. Credit verbruik van vandaag is hier niet meegerekend.</p>

          <v-data-table
              :headers="table_credits_per_client_last_30_days.headers"
              :items="this.credit_usage_per_client_last_30_days"
              :sort-by.sync="table_credits_per_client_last_30_days.currentSort"
              :sort-desc="[true]"
              mobile-breakpoint="0"
              height="350"
              class="rounded-lg"
              :items-per-page="-1"
              hide-default-footer
              :server-items-length="-1"
          >
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>


    <v-container fluid class="px-8 mb-3">
      <v-card outlined style="background-color: transparent;">
        <v-card-text>
          <v-row>
            <v-col>
              <v-select
                  v-model="dateFilterYear"
                  :items="dateFilterYearOptions"
                  outlined
                  label="Jaar"
                  hide-details
              ></v-select>
            </v-col>
            <v-col>
              <v-select
                  v-model="dateFilterMonth"
                  :items="dateFilterMonthOptions"
                  outlined
                  clearable
                  label="Maand"
                  hide-details
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>

              <v-card flat>
                <v-card-title><h4>Credit verbruik per klant</h4></v-card-title>
                <v-card-text>
                  <div class="fixed-height-description">
                    <p class="text-caption mb-0">Registraties van vandaag zijn hier niet meegerekend.</p>
                  </div>

                  <v-data-table
                      :headers="table_credits_per_client.headers"
                      :items="this.credit_count_per_client_totals"
                      mobile-breakpoint="0"
                      height="350"
                      class="mt-3 mb-9 rounded-lg"
                      :sort-by.sync="table_credits_per_client.currentSort"
                      :sort-desc="[true]"
                      :options.sync="table_credits_per_client.options"
                      :items-per-page="-1"
                      hide-default-footer
                      :server-items-length="-1"
                  >
                  </v-data-table>
                </v-card-text>
              </v-card>

            </v-col>
            <v-col>

              <v-card flat>
                <v-card-title><h4>Opslag</h4></v-card-title>
                <v-card-text>

                  <p class="text-caption mb-2 fixed-height-description">Let op: De pieken van de nachtelijk en wekelijkse backups kan je hier niet zien. Tijdens de backup wordt er aanzienlijk meer opslag gebruikt.</p>

                  <v-data-table
                      :headers="table_disk_usage.headers"
                      :items="this.reports"
                      mobile-breakpoint="0"
                      height="350"
                      class="rounded-lg mb-9"
                      :sort-by.sync="table_disk_usage.currentSort"
                      :sort-desc="[true]"
                      :options.sync="table_disk_usage.options"
                      :items-per-page="-1"
                      hide-default-footer
                      :server-items-length="-1"
                  >
                    <template v-slot:item.attributes.report_date="{ item }">
                      {{ item.attributes.report_date|formatDateOnly }}
                    </template>
                    <template #item.attributes.disk_free="{ item }">
                      {{ formatBytes(item.attributes.disk_free) }}
                    </template>
                    <template #item.attributes.disk_available="{ item }">
                      {{ item.attributes.disk_available }}%
                    </template>
                    <template #item.attributes.disk_total="{ item }">
                      {{ formatBytes(item.attributes.disk_total) }}
                    </template>
                  </v-data-table>

                  <!--          <drupal-json-api-pager-->
                  <!--              :page-prev-link="pagePrevLink"-->
                  <!--              :page-next-link="pageNextLink"-->
                  <!--              @page-change="onPageChange"-->
                  <!--          ></drupal-json-api-pager>-->

                </v-card-text>
              </v-card>

            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>


    <v-container fluid class="px-8">
      <v-row>
        <v-col>
          <v-sheet class="pa-4 rounded-lg">
            <client-chart-credit-usage></client-chart-credit-usage>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>

  </div>
</template>

<script>
import axios from "@/plugins/axios";
import ClientChartCreditUsage from "@/components/ClientChartCreditUsage.vue";
// import DrupalJsonApiPager from "@/components/DrupalJsonApiPager";

export default {
  components: {
    ClientChartCreditUsage
    // DrupalJsonApiPager,
  },
  mounted () {
    this.getReportsOnFilteredDateFromApi()
    this.getReportsLast30DaysFromApi()
    // Set current date
    this.registrations_date = this.yesterdayDateFormatted();
    // Set default filter values to current year and month
    this.dateFilterYear = new Date().getFullYear();
    this.dateFilterMonth = new Date().getMonth();
  },
  data () {
    return {
      // pageNextLink: '',
      // pagePrevLink: '',
      dateFilterMonthOptions: [
        {value: 1, text: 'Januari'},
        {value: 2, text: 'Februari'},
        {value: 3, text: 'Maart'},
        {value: 4, text: 'April'},
        {value: 5, text: 'Mei'},
        {value: 6, text: 'Juni'},
        {value: 7, text: 'Juli'},
        {value: 8, text: 'Augustus'},
        {value: 9, text: 'September'},
        {value: 10, text: 'Oktober'},
        {value: 11, text: 'November'},
        {value: 12, text: 'December'},
      ],
      dateFilterYear: null,
      dateFilterMonth: null,
      registrations_date: '',
      reports: [],
      reports_last_30_days: [],
      credit_usage_clients: [],
      table_disk_usage: {
        currentSort: ['attributes.report_date'],
        options: {
          itemsPerPage: -1,
        },
        headers: [
          {
            text: 'Datum',
            align: 'start',
            sortable: true,
            value: 'attributes.report_date',
          },
          {
            text: 'Beschikbaar',
            align: 'start',
            sortable: true,
            value: 'attributes.disk_free',
          },
          {
            text: 'Beschikbaar %',
            align: 'start',
            sortable: true,
            value: 'attributes.disk_available',
          },
          {
            text: 'Capaciteit',
            align: 'start',
            sortable: true,
            value: 'attributes.disk_total',
          },
        ],
      },
      table_credits_per_client_last_30_days: {
        currentSort: ['credits_total'],
        options: {
          itemsPerPage: -1,
        },
        headers: [
          {
            text: 'Klant',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          {
            text: 'Credits totaal',
            align: 'start',
            sortable: true,
            value: 'credits_total',
          },
        ],
      },
      table_credits_per_client: {
        currentSort: ['credits_total'],
        options: {
          itemsPerPage: -1,
        },
        headers: [
          {
            text: 'Klant',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          {
            text: 'Credits totaal',
            align: 'start',
            sortable: true,
            value: 'credits_total',
          },
        ],
      }
    }
  },
  computed: {
    dateFilterYearOptions() {
      let max = new Date().getFullYear()
      let min = max - 9
      let years = []

      for (let i = max; i >= min; i--) {
        years.push(i)
      }
      return years
    },
    credit_usage_per_client_last_30_days() {
      let credits = {};

      // Loop over all reports
      for (let report of this.reports_last_30_days) {
        if (!report.attributes.credit_usage_per_client) {
          continue;
        }
        // Loop over all 'credit_usage_per_client'
        for (let credit_usage of report.attributes.credit_usage_per_client) {
          if (credits[credit_usage.client_id]) {
            // Add credits
            credits[credit_usage.client_id].credits_total += parseInt(credit_usage.credit_count);
          }
          else {
            // Create new credit usage
            credits[credit_usage.client_id] = {
              name: credit_usage.client_name,
              credits_total: parseInt(credit_usage.credit_count),
            }
          }
        }
      }

      // Convert credit object to array
      return Object.values(credits);
    },
    registration_count_per_client_totals() {
      let registrations = {};
      // Loop over all reports
      for (let report of this.reports) {
        if (!report.attributes.registration_count_per_client) {
          continue;
        }
        // Loop over all 'registration_count_per_client'
        for (let registration_count of report.attributes.registration_count_per_client) {
          if (registrations[registration_count.client_id]) {
            // Add registration count
            registrations[registration_count.client_id].registrations_total += registration_count.count;
          }
          else {
            // Create new registration count
            registrations[registration_count.client_id] = {
              name: registration_count.client_name,
              registrations_total: registration_count.count,
            }
          }
        }
      }
      // Convert credit object to array
      return Object.values(registrations);
    },
    credit_count_per_client_totals() {
      let credits = {};

      // Loop over all reports
      for (let report of this.reports) {
        if (!report.attributes.credit_usage_per_client) {
          continue;
        }
        // Loop over all 'credit_usage_per_client'
        for (let credit_usage of report.attributes.credit_usage_per_client) {
          if (credits[credit_usage.client_id]) {
            // Add credits
            credits[credit_usage.client_id].credits_total += parseInt(credit_usage.credit_count);
          }
          else {
            // Create new credit usage
            credits[credit_usage.client_id] = {
              name: credit_usage.client_name,
              credits_total: parseInt(credit_usage.credit_count),
            }
          }
        }
      }

      // Convert credit object to array
      return Object.values(credits);
    },
    registration_count_per_client_per_day() {
      let registrations = {};

      // Find the selected day
      let report = this.reports.find(obj => {
        return obj.attributes.report_date === this.registrations_date
      })
      if (report) {
        // Loop over all 'registration_count_per_client'
        if (report.attributes.registration_count_per_client) {
          for (let registration_count of report.attributes.registration_count_per_client) {
            if (registrations[registration_count.client_id]) {
              // Add registration count
              registrations[registration_count.client_id].registrations_total += registration_count.count;
            }
            else {
              // Create new registration count
              registrations[registration_count.client_id] = {
                name: registration_count.client_name,
                registrations_total: registration_count.count,
              }
            }
          }
        }
      }

      // Convert credit object to array
      return Object.values(registrations);
    },
    allReportDates() {
      let dates = [];

      for (let report of this.reports) {
        dates.push(report.attributes.report_date)
      }
      return dates;
    },
  },
  watch: {
    dateFilterYear() {
      this.getReportsOnFilteredDateFromApi()
    },
    dateFilterMonth() {
      this.getReportsOnFilteredDateFromApi()
    },
  },
  methods: {
    async getReportsOnFilteredDateFromApi(link = 'jsonapi/dashboard_report/dashboard_report') {
      // Filter on year only
      let dateFilter = this.dateFilterYear;
      // Filter on year + month
      if (this.dateFilterMonth) {
        dateFilter = this.dateFilterYear + '-' + (this.dateFilterMonth).toString().padStart(2, '0')
      }

      let config = {
        params: {
          'filter[report_start][condition][path]': 'report_date',
          'filter[report_start][condition][operator]': 'STARTS_WITH',
          'filter[report_start][condition][value]': dateFilter,
          'sort': '-report_date', // Sort in descending order by appending a minus
          'page[limit]': 366,
        }
      }

      try {
        // Fetch clients
        let response = await axios.get(link, config);
        // Set items
        this.reports = response.data.data

        // Set pager links
        this.pagePrevLink = response.data.links.prev ? response.data.links.prev.href : ''
        this.pageNextLink = response.data.links.next ? response.data.links.next.href : ''

      } catch (error) {
        console.log(error);
      }
    },
    async getReportsLast30DaysFromApi(link = 'jsonapi/dashboard_report/dashboard_report') {
      // Get 30 days ago
      let today = new Date();
      let date30DaysAgo = new Date(new Date().setDate(today.getDate() - 30));
      let date30DaysAgoFormatted = date30DaysAgo.toISOString().split('T')[0]

      let config = {
        params: {
          'filter[report_date][path]': 'report_date',
          'filter[report_date][operator]': '>=',
          'filter[report_date][value]': date30DaysAgoFormatted,
          'sort': '-report_date', // Sort in descending order by appending a minus
          'page[limit]': 30,
        }
      }

      try {
        // Fetch clients
        let response = await axios.get(link, config);
        // Set items
        this.reports_last_30_days = response.data.data

      } catch (error) {
        console.log(error);
      }
    },
    rowClick(item) {
      this.$router.push({ name: 'client_view', params: {client_id: item.id} })
    },
    scrollToTop() {
      window.scrollTo(0,0);
    },
    onPageChange(link) {
      this.getReportsOnFilteredDateFromApi(link)
      this.scrollToTop()
    },
    formatBytes(bytes, decimals = 2) {
      if (!+bytes) return '0 Bytes'

      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

      const i = Math.floor(Math.log(bytes) / Math.log(k))

      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    },
    currentDateFormatted() {
      const date = new Date();

      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();

      let currentDate = `${year}-${month}-${day}`;
      return currentDate;
    },
    yesterdayDateFormatted() {
      let d = new Date();
      d.setDate(d.getDate() - 1);
      return d.toISOString().split('T')[0];
    },
    allowedDates(val) {
      // const dateIsPast = val <= new Date().toISOString().substr(0, 10);
      let dateExistsInReport = this.allReportDates.indexOf(val) !== -1;
      return dateExistsInReport;
    },
  },
}
</script>

<style scoped>
.fixed-height-description {
  min-height: 50px;
}
</style>