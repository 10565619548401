var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"pa-4 rounded-lg",attrs:{"flat":""}},[_c('v-card-title',[_vm._v("Groep")]),(_vm.isLoading)?_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-sheet',{staticClass:"pa-4 rounded-lg"},[_c('v-skeleton-loader',{attrs:{"type":"sentences, text@3, image"}})],1)],1)],1):_vm._e(),(!_vm.isLoading)?_c('v-card-text',[_vm._l((_vm.errorMessages),function(errorMessage,key){return _c('v-alert',{key:key,attrs:{"type":"error","dismissible":""}},[_vm._v(" "+_vm._s(errorMessage)+" ")])}),_c('FormulateForm',{on:{"submit":_vm.submitForm},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isSaving = ref.isSaving;
return [_c('FormulateInput',{attrs:{"type":"text","label":"Groepnaam","validation":"required"},model:{value:(_vm.group.label),callback:function ($$v) {_vm.$set(_vm.group, "label", $$v)},expression:"group.label"}}),_c('FormulateInput',{staticClass:"mt-5",attrs:{"input-class":"v-btn v-size--default success","type":"submit","name":"submit","disabled":isSaving,"label":isSaving ? 'Laden...' : 'Groep opslaan'}})]}}],null,false,3441936260)})],2):_vm._e()],1),_c('v-expansion-panels',{staticClass:"mt-6 rounded-lg",attrs:{"flat":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('v-card-title',{staticClass:"pa-2"},[_c('span',{staticClass:"mr-4"},[_vm._v("Formulieren")]),_c('p',{staticClass:"caption ma-0"},[_vm._v("Formulieren waar deze group toegang tot heeft")])])],1),_c('v-expansion-panel-content',[_c('v-data-table',{attrs:{"headers":this.user_group_forms_headers,"items":this.user_group_forms,"mobile-breakpoint":"0","sort-by":"form_name","options":{itemsPerPage: -1}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"ml-2",attrs:{"outlined":"","small":"","color":"primary","router":"","to":{ name: 'form_view', params: {form_id: item.form_id} }}},[_c('v-icon',{attrs:{"depressed":"","role":"button","left":""}},[_vm._v("mdi-text-box-outline")]),_vm._v(" Bekijk formulier ")],1)]}}])})],1)],1)],1),_c('v-expansion-panels',{staticClass:"mt-6 rounded-lg",attrs:{"flat":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{on:{"click":_vm.toggleAddUsersPanel}},[_c('v-card-title',{staticClass:"pa-2"},[_c('span',{staticClass:"mr-4"},[_vm._v("Gebruikers toevoegen")]),_c('p',{staticClass:"caption ma-0"},[_vm._v("Voeg een gebruiker toe aan deze groep.")])])],1),_c('v-expansion-panel-content',[_c('v-data-table',{attrs:{"headers":_vm.client_users_headers,"items":this.clientUsersNotInThisGroup,"mobile-breakpoint":"0","loading":_vm.isLoadingClientUsers,"sort-by":"display_name","options":{itemsPerPage: -1}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"ml-2",attrs:{"outlined":"","small":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.addUserToUserGroup(item)}}},[_c('v-icon',{attrs:{"depressed":"","role":"button","left":""}},[_vm._v("mdi-plus")]),_vm._v(" Toevoegen aan groep ")],1)]}}])})],1)],1)],1),_c('v-card',{staticClass:"pa-4 mt-6 rounded-lg",attrs:{"flat":""}},[_c('v-card-title',[_c('span',{staticClass:"mr-4"},[_vm._v("Gebruikers")]),_c('p',{staticClass:"caption ma-0"},[_vm._v("Deze gebruikers horen bij de groep \""+_vm._s(_vm.group.label)+"\". Deze gebruikers hebben toegang tot alle formulieren waar deze groep bij hoort.")])]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.user_headers,"items":_vm.group.users,"mobile-breakpoint":"0","loading":_vm.isLoading,"sort-by":"display_name","options":{itemsPerPage: -1}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"ml-2",attrs:{"outlined":"","small":"","color":"red","disabled":_vm.isSaving},on:{"click":function($event){$event.stopPropagation();return _vm.removeUserFromGroupConfirm(item)}}},[_c('v-icon',{attrs:{"depressed":"","role":"button","left":""}},[_vm._v("mdi-trash-can")]),_vm._v(" Verwijder uit groep ")],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }