// Or with import
import Vue from "vue";
import axios from "axios";
import store from "@/plugins/store";
import router from "@/plugins/routes";

axios.defaults.baseURL = process.env.VUE_APP_API_BASEURL;
// axios.defaults.baseURL = 'https://eform.local/';

axios.interceptors.request.use(function (config) {
    // Add 'key_auth' token to all axios requests
    const token = store.state.auth.authToken
    if (token) {
        config.headers['api-key'] = token;
    }
    return config;
});

// Redirect user to '/login' when user hits a '401' error. Meaning the auth token has expired.
axios.interceptors.response.use(undefined, function (error) {
    if (error) {
        // Vue.$toast.error("Something went wrong while connecting to the server.");
        const originalRequest = error.config;
        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            Vue.prototype.$rollbar.warning('Axios error response. HTTP 401: Unauthorized');
            originalRequest._retry = true;
            return router.push({ name: 'logout'})
        }
        else if (error.response && error.response.status === 403 && !originalRequest._retry) {
            Vue.prototype.$rollbar.warning('Axios error response. HTTP 403: Forbidden');
            Vue.$toast.error("U hebt geen rechten om deze content te bekijken.");
            return router.push({ name: 'dashboard'});
        }
        // else if (error.response && error.response.status === 404 && !originalRequest._retry) {
        //     Vue.prototype.$rollbar.info('Axios error response. HTTP 404: Page not found');
        //     return router.push({name: '404'});
        // }
        else {
            Vue.prototype.$rollbar.error('Unknown axios error response.');
        }
        // Other rejected statuses
        // let url = (originalRequest) ? ' URL:'+originalRequest.url : '';
        // Vue.$toast.error("Er is iets misgegaan tijdens het verbinden met de server."+url);
    }

    return Promise.reject(error);
})

// Show loader when requests are pending
axios.interceptors.request.use(
    config => {
        store.dispatch('loader/pending');
        return config;
    },
    error => {
        store.dispatch('loader/done');
        return Promise.reject(error);
    }
);
// Hide loader when responses are done
axios.interceptors.response.use(
    response => {
        store.dispatch('loader/done');
        return response;
    },
    error => {
        store.dispatch('loader/done');
        return Promise.reject(error);
    }
)

export default axios