import Vue from 'vue';
import VueRouter from 'vue-router';
import store from './store';

Vue.use(VueRouter);

let routes = [
    {
        name: 'form',
        path: '/admin/form/:form_id',
        component: require('../views/forms/Form').default,
        meta: {
            title: 'Bekijk formulier',
            requiresAuth: true,
        },
        props: true,
        children: [
            {
                name: 'form_view',
                path: '',
                component: require('../views/forms/FormView').default,
                props: true,
                meta: {
                    title: 'Formulier bekijken',
                    titleHidden: true,
                    requiresAuth: true,
                },
            },
            {
                name: 'form_edit',
                path: 'edit',
                component: require('../views/forms/FormEdit').default,
                props: true,
                meta: {
                    title: 'Formulier bewerken',
                    titleHidden: true,
                    requiresAuth: true,
                },
            },
            {
                name: 'form_remove',
                path: 'remove',
                component: require('../views/forms/FormRemove').default,
                props: true,
                meta: {
                    title: 'Formulier verwijderen',
                    titleHidden: true,
                    requiresAuth: true,
                },
            },
            {
                name: 'form_emails',
                path: 'emails',
                component: require('../views/forms/FormEmails').default,
                props: true,
                meta: {
                    title: 'Formulier e-mails',
                    titleHidden: true,
                    requiresAuth: true,
                },
            },
            {
                name: 'form_rules',
                path: 'rules',
                component: require('../views/forms/FormRules').default,
                props: true,
                meta: {
                    title: 'Formulier regels',
                    titleHidden: true,
                    requiresAuth: true,
                },
            },
            {
                name: 'form_registrations',
                path: 'registrations',
                component: require('../views/registrations/FormRegistrationList').default,
                props: true,
                meta: {
                    title: 'Formulier registraties',
                    titleHidden: true,
                    requiresAuth: true,
                },
            },
            {
                name: 'form_registrations_view',
                path: 'registrations/:registration_id',
                component: require('../views/registrations/FormRegistrationView').default,
                props: true,
                meta: {
                    title: 'Formulier registratie',
                    titleHidden: true,
                    requiresAuth: true,
                },
            },
            {
                name: 'form_integrations',
                path: 'integrations',
                component: require('../views/forms/FormIntegrations').default,
                props: true,
                meta: {
                    title: 'Formulier integraties',
                    titleHidden: true,
                    requiresAuth: true,
                },
            },

        ]
    },
    {
        name: 'form_fill',
        path: '/form/:form_id/:force_reload',
        component: require('../views/forms/FormFill').default,
        props: true,
        meta: {
            title: 'Bekijk formulier',
            titleHidden: true,
            requiresAuth: true,
            breadcrumb: [
                { name: 'Dashboard', route: 'dashboard' },
                { name: 'Overzicht', route: 'folder_list' },
                { name: 'Formulier' }
            ]
        },
    },
    {
        name: 'form_fill_workflow',
        path: '/form-workflow/:form_id/:registration_id',
        component: require('../views/forms/FormFill').default,
        props: true,
        meta: {
            title: 'Bekijk formulier',
            titleHidden: true,
            requiresAuth: true,
            breadcrumb: [
                { name: 'Dashboard', route: 'dashboard' },
                { name: 'Overzicht', route: 'assigned_registration_list' },
                { name: 'Formulier' }
            ]
        },
    },
    {
        name: 'folder_list',
        path: '/folders',
        component: require('../views/folders/FolderList').default,
        meta: {
            title: 'Mappen',
            requiresAuth: true,
            breadcrumb: [
                { name: 'Dashboard', route: 'dashboard' },
                { name: 'Folders' },
            ]
        },
    },
    {
        name: 'folder_form_list',
        path: '/folder/:folder_id/forms',
        component: require('../views/forms/FolderFormList').default,
        meta: {
            title: 'Formulieren invullen',
            requiresAuth: true,
            titleHidden: true,
            breadcrumb: [
                { name: 'Dashboard', route: 'dashboard' },
                { name: 'Formulieren invullen' },
            ]
        },
    },
    {
        name: 'unsend_registration_list',
        path: '/unsend-registration-list',
        component: require('../views/forms/UnsendRegistrationList').default,
        meta: {
            title: 'Opgeslagen registraties',
            requiresAuth: true,
            breadcrumb: [
                { name: 'Dashboard', route: 'dashboard' },
            ]
        },
    },
    {
        name: 'assigned_registration_list',
        path: '/assigned-registration-list',
        component: require('../views/forms/AssignedRegistrationList.vue').default,
        meta: {
            title: 'Toegewezen registraties',
            requiresAuth: true,
            breadcrumb: [
                { name: 'Dashboard', route: 'dashboard' },
            ]
        },
    },
    {
        name: 'form_list_admin',
        path: '/admin/form-list',
        component: require('../views/forms/FormListAdmin').default,
        meta: {
            title: 'Formulieren beheren',
            requiresAuth: true,
            breadcrumb: [
                { name: 'Dashboard', route: 'dashboard' },
                { name: 'Formulieren beheren' },
            ]
        },
    },
    {
        name: 'folder_form_list_admin',
        path: '/admin/form-list/folder/:folder_id',
        component: require('../views/folders/FolderFormListAdmin').default,
        meta: {
            title: 'Formulieren in map beheren',
            titleHidden: true,
            requiresAuth: true,
            breadcrumb: [
                { name: 'Dashboard', route: 'dashboard' },
                { name: 'Formulieren beheren' },
                { name: 'Formulieren in map beheren' },
            ]
        },
    },
    {
        name: 'folder_list_admin',
        path: '/admin/folder-list',
        component: require('../views/folders/FolderManageAdmin').default,
        meta: {
            title: 'Mappen beheren',
            requiresAuth: true,
            breadcrumb: [
                { name: 'Dashboard', route: 'dashboard' },
                { name: 'Mappen beheren' },
            ]
        },
    },
    {
        name: 'form_create',
        path: '/admin/form-create',
        component: require('../views/forms/FormCreate').default,
        meta: {
            title: 'Formulier aanmaken',
            requiresAuth: true,
        },
    },
    {
        name: 'folder_create',
        path: '/admin/folder-create',
        component: require('../views/folders/FolderCreate').default,
        meta: {
            title: 'Map aanmaken',
            requiresAuth: true,
        },
    },
    {
        name: 'datasource_list',
        path: '/datasources',
        component: require('../views/datasources/DatasourceList').default,
        meta: {
            title: 'Databronnen',
            requiresAuth: true,
        },
    },
    {
        name: 'datasource_create',
        path: '/datasources/create/:datasource_type',
        component: require('../views/datasources/DatasourceCreate').default,
        meta: {
            title: 'Databron aanmaken',
            requiresAuth: true,
        },
        props: true,
    },
    {
        name: 'datasource',
        path: '/datasources/:datasource_type/:datasource_id',
        component: require('../views/datasources/Datasource').default,
        meta: {
            title: 'Bekijk databron',
            requiresAuth: true,
        },
        props: true,
        children: [
            {
                name: 'datasource_view',
                path: '',
                component: require('../views/datasources/DatasourceView').default,
                props: true,
                meta: {
                    title: 'Databron bekijken',
                    titleHidden: true,
                    requiresAuth: true,
                },
            },
            {
                name: 'datasource_edit',
                path: 'edit',
                component: require('../views/datasources/DatasourceEdit').default,
                props: true,
                meta: {
                    title: 'Databron bewerken',
                    titleHidden: true,
                    requiresAuth: true,
                },
            },
            {
                name: 'datasource_remove',
                path: 'remove',
                component: require('../views/datasources/DatasourceRemove').default,
                props: true,
                meta: {
                    title: 'Databron verwijderen',
                    titleHidden: true,
                    requiresAuth: true,
                },
            },
        ]
    },
    {
        name: 'admin_dashboard',
        path: '/admin/dashboard',
        component: require('../views/admin/AdminDashboard.vue').default,
        meta: {
            title: 'Admin dashboard',
            requiresAuth: true,
            requiresAdmin: true,
        },
    },
    {
        name: 'client_list_admin',
        path: '/clients/admin',
        component: require('../views/clients/ClientListAdmin').default,
        meta: {
            title: 'Klanten',
            requiresAuth: true,
            requiresAdmin: true,
        },
    },
    {
        name: 'client_list_assigned',
        path: '/clients/assigned',
        component: require('../views/clients/ClientListAssigned').default,
        meta: {
            title: 'Klanten',
            requiresAuth: true,
        },
    },
    {
        name: 'client_create',
        path: '/clients/create',
        component: require('../views/clients/ClientCreate').default,
        meta: {
            title: 'Klant aanmaken',
            requiresAuth: true,
        },
        props: true,
    },
    {
        name: 'client',
        path: '/clients/:client_id',
        component: require('../views/clients/Client').default,
        meta: {
            title: 'Bekijk klant',
            requiresAuth: true,
        },
        props: true,
        children: [
            {
                name: 'client_view',
                path: '',
                component: require('../views/clients/ClientView').default,
                props: true,
                meta: {
                    title: 'Klant bekijken',
                    titleHidden: true,
                    requiresAuth: true,
                },
            },
            {
                name: 'client_edit',
                path: 'edit',
                component: require('../views/clients/ClientEdit').default,
                props: true,
                meta: {
                    title: 'Klant bewerken',
                    titleHidden: true,
                    requiresAuth: true,
                },
            },
            {
                name: 'client_users',
                path: 'users',
                component: require('../views/clients/ClientUsers').default,
                props: true,
                meta: {
                    title: 'Klant gebruikers',
                    titleHidden: true,
                    requiresAuth: true,
                },
            },
            {
                name: 'client_credits',
                path: 'credits',
                component: require('../views/clients/ClientCredits').default,
                props: true,
                meta: {
                    title: 'Klant credits',
                    titleHidden: true,
                    requiresAuth: true,
                },
            },
            {
                name: 'client_credits_admin_add',
                path: 'credits-admin-add',
                component: require('../views/clients/ClientCreditsAdminAdd').default,
                props: true,
                meta: {
                    title: 'Handmatig credits toevoegen',
                    titleHidden: true,
                    requiresAuth: true,
                },
            },
            {
                name: 'client_credits_buy',
                path: 'credits-buy',
                component: require('../views/clients/ClientCreditsBuy').default,
                props: true,
                meta: {
                    title: 'Credits kopen',
                    titleHidden: true,
                    requiresAuth: true,
                },
            },
            {
                name: 'client_modules_buy',
                path: 'modules-buy',
                component: require('../views/clients/ClientModulesBuy').default,
                props: true,
                meta: {
                    title: 'Modules kopen',
                    titleHidden: true,
                    requiresAuth: true,
                },
            },
            {
                name: 'client_user_add',
                path: 'user-add',
                component: require('../views/clients/ClientUserAdd').default,
                props: true,
                meta: {
                    title: 'Gebruikers toevoegen aan klant',
                    titleHidden: true,
                    requiresAuth: true,
                },
            },
            {
                name: 'client_user_edit',
                path: 'user/:user_id/edit',
                component: require('../views/clients/ClientUserEdit').default,
                props: true,
                meta: {
                    title: 'Gebruikers bewerken',
                    titleHidden: true,
                    requiresAuth: true,
                },
            },
            {
                name: 'client_user_groups',
                path: 'user-groups',
                component: require('../views/clients/ClientUserGroups').default,
                props: true,
                meta: {
                    title: 'Klant gebruiker groepen',
                    titleHidden: true,
                    requiresAuth: true,
                },
            },
            {
                name: 'client_user_group_add',
                path: 'user-group-add',
                component: require('../views/clients/ClientUserGroupAdd').default,
                props: true,
                meta: {
                    title: 'Groep toevoegen aan klant',
                    titleHidden: true,
                    requiresAuth: true,
                },
            },
            {
                name: 'client_user_group_edit',
                path: 'group/:group_id/edit',
                component: require('../views/clients/ClientUserGroupEdit').default,
                props: true,
                meta: {
                    title: 'Groep bewerken',
                    titleHidden: true,
                    requiresAuth: true,
                },
            },
            {
                name: 'client_remove',
                path: 'remove',
                component: require('../views/clients/ClientRemove').default,
                props: true,
                meta: {
                    title: 'Klant verwijderen',
                    titleHidden: true,
                    requiresAuth: true,
                },
            },
        ]
    },
    {
        name: 'user_register',
        path: '/register/:client_uuid/:form_id?',
        component: require('../views/UserRegister').default,
        props: true,
        meta: {
            title: 'Registreren',
            titleHidden: false,
            requiresAuth: false,
        },
    },
    {
        name: 'home',
        path: '/',
        component: require('../views/Home').default,
        meta: {
            title: 'Home',
        },
    },
    {
        name: 'dashboard',
        path: '/dashboard',
        component: require('../views/Dashboard').default,
        meta: {
            title: 'Dashboard',
            requiresAuth: true,
        },
    },
    {
        name: 'login',
        path: '/login',
        component: require('../views/Login').default,
        meta: {
            title: 'Login',
            titleHidden: true,
            guest: true
        },
    },
    {
        name: 'logout',
        path: '/logout',
        component: require('../views/Logout').default,
        meta: {
            title: 'Logout',
            titleHidden: true,
            requiresAuth: true
        },
    },
    {
        name: 'guest_map',
        path: '/guest/map/:map_uuid',
        component: require('../views/maps/GuestMap').default,
        meta: {
            title: 'Kaartweergave',
            titleHidden: true,
            requiresAuth: false
        },
    },
    {
        name: 'guest_map_form',
        path: '/guest/map/:form_id/:element_name',
        component: require('../views/maps/GuestMap').default,
        meta: {
            title: 'Kaartweergave',
            titleHidden: true,
            requiresAuth: false
        },
    },
    {
        name: 'guest_form',
        path: '/guest/form/:guest_id',
        component: require('../views/forms/GuestFormFill').default,
        props: true,
        meta: {
            title: 'Bekijk formulier',
            titleHidden: true,
            requiresAuth: false
        },
    },
    {
        name: 'map_tracking',
        path: '/map/tracking/:form_id/:registration_id',
        component: require('../views/maps/TrackingMap').default,
        meta: {
            title: 'Kaartweergave',
            titleHidden: true,
            requiresAuth: true
        },
    },
    {
        name: 'ios_debugging',
        path: '/ios-debugging',
        component: require('../views/iosDebugging').default,
        meta: {
            title: 'iOS debugging',
        },
    },
    {
        name: '404',
        path: '/404',
        component: require('../views/404').default,
        meta: {
            title: 'Pagina niet gevonden',
        },
    },
    { // Last path, redirects leftovers to 404
        path: "*",
        redirect: '404',
    },
];

const router = new VueRouter({
    // mode: 'history',
    // base: process.env.BASE_URL,
    routes
})

/**
 * Wait for the Vuex persist storage to be been fully restored.
 * This is needed because we use localforage, which is async.
 *
 * @param to
 * @param from
 * @param next
 * @returns {Promise<void>}
 */
const waitForStorageToBeReady = async (to, from, next) => {
    await store.restored
    next()
}
router.beforeEach(waitForStorageToBeReady)


/**
 * Protect pages that require authenticated user
 */
router.beforeEach((to, from, next) => {
    // Redirect unauthenticated users to '/login' when they navigates to a route that has {requiresAuth: true}
    if (!store.getters.isAuthenticated && to.matched.some(record => record.meta.requiresAuth)) {
        console.log('Redirect to login. User is not authenticated and is not permitted to access route with flag requiresAuth: "'+to.name+'" From: "'+from.name+'"');
        next({name: 'login'})
    } else {
        next()
    }
});

/**
 * Protect pages that require admin user
 */
router.beforeEach((to, from, next) => {
    // Redirect users to '/login' when they navigates to a route that has {requiresAdmin: true}
    if (!store.getters.userIsAdmin && to.matched.some(record => record.meta.requiresAdmin)) {
        console.log('Redirect to login. User is not authenticated and is not permitted to access route with flag requiresAdmin: "'+to.name+'" From: "'+from.name+'"');
        next({name: 'login'})
    } else {
        next()
    }
});

/**
 * Redirect authenticated user to their dashboard when going to 'guest' routes
 */
router.beforeEach((to, from, next) => {
    // The meta: {guest: true} stops users that are logged in from accessing the routes with the guest meta.
    if (store.getters.isAuthenticated && to.matched.some((record) => record.meta.guest)) {
        console.log('Redirect to route "'+store.getters.userDashboardRouteName+'". User is authenticated and is not permitted to access guest route: "'+to.name+'" From: "'+from.name+'"');
        next({name: store.getters.userDashboardRouteName});
    } else {
        next();
    }
});

export default router