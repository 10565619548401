<template>
  <div>

    <v-container fluid class="pa-md-8 pt-md-2">
      <v-row>
        <v-col>
          <ul class="text-caption">
            <li>Bestanden in offline opgeslagen registraties blijven 31 dagen bewaard.</li>
            <li>Opgeslagen registraties ouder dan één jaar worden automatisch verwijderd.</li>
          </ul>
        </v-col>
      </v-row>
    </v-container>

    <v-data-table
        :headers="headers"
        :loading="loading"
        :items="this.$store.state.unsyncedRegistrations"
        class="mx-sm-8 rounded-lg"
        mobile-breakpoint="0"
        sort-by="registration_updated_at"
        :sort-desc="[true]"
        :options.sync="options"
        :footer-props="{'items-per-page-options': [10, 25, 50, 100, -1]}"
        v-if="this.$store.state.unsyncedRegistrations.length"
        @click:row="rowClick"
    >
      <template #item.form_name="{ item }">
        <span :class="{ 'text-decoration-line-through': item.registration_saved_deleted_at }">
          {{ item.form_name }}
        </span>
        <v-chip small v-if="hasConflict(item.fill_token)" color="primary"><v-icon small>mdi-alert</v-icon> Nieuwe versie beschikbaar</v-chip>
        <v-chip small v-if="item.unsynced">Alleen op dit apparaat</v-chip>
      </template>
      <template #item.form_summary="{ item }">
        <div
            class="form-summary"
            v-if="item.form_summary"
            v-html="replaceTokens(item.form_summary, getTokensFromSchema(item.schema), item.values, true, item.schema)"
        ></div>
      </template>
      <template #item.registration_updated_at="{ item }">
        {{ item.registration_updated_at|formatDate }}
      </template>
      <template #item.actions="{ item }">
        <v-btn outlined small color="secondary" v-if="hasConflict(item.fill_token)" @click.stop="openConflictModal(item)"><v-icon>mdi-source-branch</v-icon>Kies versie</v-btn>
        <v-btn outlined small color="error" v-if="!item.registration_saved_deleted_at || !item.fill_token" @click.stop="deleteRegistrationConfirm(item)"><v-icon>mdi-trash-can</v-icon>Verwijder</v-btn>
      </template>
    </v-data-table>

    <v-container fluid class="pa-md-8 pt-md-2" v-else>
      <v-row>
        <v-col>
          U heeft momenteel geen onverstuurde registraties.
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="conflictModal" max-width="700" scrollable>
      <v-card v-if="activeConflict">
        <v-card-title>Kies een registratie versie</v-card-title>
        <v-card-text>
          Er is op de server een nieuwere versie van deze registratie gevonden. Welke versie wilt u behouden? De andere versie wordt permanent vernietigd.

          <v-container class="px-0">
            <v-row>
              <v-col>
               <v-card outlined>
                 <v-card-title>Versie op dit lokale apparaat</v-card-title>
                 <v-card-text>
                   Laatst aangepast op: <br>
                   <strong>{{ activeConflict.client_version.registration_updated_at|formatDate }}</strong>
                 </v-card-text>
                 <v-card-actions>
                   <v-btn depressed color="primary" @click="resolveConflict('client_version')">Kies lokale versie</v-btn>
                 </v-card-actions>
               </v-card>
              </v-col>
              <v-col>
                <v-card outlined>
                  <v-card-title>Versie op de server</v-card-title>
                  <v-card-text>
                    Laatst aangepast op: <br>
                    <strong>{{ activeConflict.server_version.registration_updated_at|formatDate }}</strong>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn depressed color="primary" @click="resolveConflict('server_version')">Kies server versie</v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn outlined color="secondary" @click="closeConflictModal">Annuleren</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import axios from "axios";
import store from "@/plugins/store";
import _ from "lodash";
import eformHelpers from "@/mixins/eformHelpers";

export default {
  name: "ListForms",
  mixins: [
    eformHelpers
  ],
  data () {
    return {
      conflictModal: false,
      conflicts: [],
      activeConflict: null,
      loading: false,
      options: {
        itemsPerPage: -1
      },
      headers: [
        {
          text: 'Formulier naam',
          sortable: true,
          align: 'start',
          value: 'form_name',
        },
        {
          text: 'Samenvatting',
          sortable: true,
          align: 'start',
          value: 'form_summary',
        },
        {
          text: 'Laatste wijziging',
          sortable: true,
          align: 'start',
          value: 'registration_updated_at',
        },
        {
          text: 'Acties',
          value: 'actions',
        },
      ],
    }
  },
  mounted () {
    this.syncSavedRegistrations()
  },
  methods: {
    rowClick(item) {
      this.$router.push({ name: 'form_fill', params: {form_id: item.form_id, force_reload: '0', fill_token: item.fill_token} })
    },
    async syncSavedRegistrations() {
      this.loading = true
      let registrations = this.$store.state.unsyncedRegistrations
      // Sync the saved registration
      await axios.post('api/saved-registrations-sync', registrations)
          .then(response => {
            if (response && response.status === 201) {
              // Success, replace our entire saved registrations with the version of the server
              store.commit('replaceAllSavedRegistrations', {
                registrations: response.data.registrations
              });
              // Save the conflicts
              this.conflicts = response.data.conflicts
            }
            else {
              this.$toast.warning('Registraties konden niet gesynchroniseerd worden.');
            }
          });
      this.loading = false
    },
    hasConflict(fill_token) {
      let hasConflict = this.findConflictByFillToken(fill_token)
      if (hasConflict) {
        return true
      }
      else {
        return false
      }
    },
    async resolveConflict(version) {
      // Replace the registration in the Vuex store with the chosen version.
      if (version === 'client_version') {
        // This action also updates the 'registration_updated_at' and thus making this the new version when we sync again.
        await store.commit('createOrUpdateSavedRegistration', {
          form: this.activeConflict.client_version
        });
      }
      else if (version === 'server_version') {
        // This action only replaces the registration without updating the 'registration_updated_at'
        await store.commit('replaceSavedRegistration', {
          form: this.activeConflict.server_version
        });
      }
      // Resync with server
      this.syncSavedRegistrations()
      // Close the modal
      this.closeConflictModal()
    },
    findConflictByFillToken(find_fill_token) {
      return this.conflicts.find(({ fill_token }) => fill_token === find_fill_token);
    },
    openConflictModal(item) {
      // Set the conflict we want to resolve
      this.activeConflict = this.findConflictByFillToken(item.fill_token)
      // Open conflict modal
      this.conflictModal = true
    },
    closeConflictModal() {
      this.conflictModal = false
    },
    async deleteRegistrationConfirm(item) {
      if (
          confirm("Weet je zeker dat je deze registratie wilt verwijderen?")
      ) {
        this.deleteRegistration(item);
      }
    },
    async deleteRegistration(item) {
      // Get the saved registration
      let registration = item

      // Set the delete date to delete it
      let date_now = new Date();
      registration.registration_saved_deleted_at = date_now.toISOString()

      // This action also updates the 'registration_updated_at' and thus making this the new version when we sync again.
      await store.commit('createOrUpdateSavedRegistration', {
        form: registration
      });

      // Remove stuck registrations without fill_token
      this.removeSavedRegistrationWithoutFillTokenFromClient(registration);

      // Resync with server
      this.syncSavedRegistrations()
    },
    /**
     * In some cases a registration doesn't have a 'fill_token'.
     * When that happens the server can't find the registration and won't sync it. Leaving the registration stuck forever on the client device.
     * - Permanently removes the registration from the client device.
     *
     * @param registration
     */
    removeSavedRegistrationWithoutFillTokenFromClient(registration) {
      if (
          !_.has(registration, 'fill_token') &&
          _.has(registration, 'registration_saved_deleted_at')
      ) {
        store.commit('deleteSavedRegistration', {
          form: registration
        })
      }
    }
  },
  computed: {
    ...mapGetters(["appOnline"]),
  }
}
</script>

<style scoped>
.form-summary {
  p:last-child {
    margin-bottom: 0;
  }
}
</style>