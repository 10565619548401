<template>
  <div>
    <v-btn
        fab
        fixed top left
        v-if="drawerTemporary"
        rounded
        small
        color="primary"
        style="z-index: 10; top: 4px;"
        @click.stop="toggleDrawer">
      <v-icon>mdi-menu</v-icon>
    </v-btn>

    <v-navigation-drawer
        v-model="drawer"
        app
        left
        :temporary="drawerTemporary"
        class="nav_drawer background-color"
        style="height: 100%;"
    > <!-- height: 100% is needed to fix iOS bug where the v-slot:append is partly hidden by the ios address bar when user has not scrolled the page yet -->


      <template v-slot:prepend>
        <v-sheet class="background-color">
          <v-img
              class="mx-2"
              src="../assets/images/e-formapp_logo_2022.svg"
              contain
              height="70"
          ></v-img>
        </v-sheet>
        <v-divider></v-divider>
        <v-sheet v-if="!onlyFiller" class="background-color">
          <!-- Show client switcher select box -->
          <v-autocomplete
              v-model="selectedClient"
              :items="clientOptions"
              :disabled="clientOptions.length <= 1"
              :append-icon="(clientOptions.length <= 1) ? '' : '$dropdown'"
              auto-select-first
              hide-details
              flat
              filled
              label="Klant"
              style="border-radius: 0;"
              ref="activeClientAutocomplete"
              @change="activeClientInputChange"
              @blur="activeClientInputFocusLost"
          ></v-autocomplete>
          <v-divider></v-divider>
        </v-sheet>
      </template>

      <template v-slot:append>
        <v-divider></v-divider>
        <v-sheet class="background-color">
          <v-list-item two-line>
            <v-list-item-avatar @click="onAvatarClick">
              <v-icon large>mdi-account-circle</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ User.email }}</v-list-item-title>
              <template v-if="isLoggedIn">
                <router-link class="logout" router :to="{ name: 'logout'}">Uitloggen</router-link>
              </template>

            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
        </v-sheet>
        <v-sheet class="text-center pb-4 background-color">
          <span class="text-caption">v{{ appVersion }}</span>
        </v-sheet>
      </template>

      <template v-if="isLoggedIn">
        <v-list dense>
          <template v-if="onlyFiller">
            <v-list-item
                dense
                router :to="{ name: 'folder_list'}"
                exact
            >
              <v-list-item-icon>
                <v-icon>mdi-pencil-outline</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Formulier invullen</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
                dense
                router :to="{ name: 'unsend_registration_list'}"
                exact
            >
              <v-list-item-icon>
                <v-icon>mdi-sync</v-icon>
                <v-badge :content="unsendRegistrations" v-if="unsendRegistrations" overlap></v-badge>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Opgeslagen registraties</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
                dense
                router :to="{ name: 'assigned_registration_list'}"
                exact
            >
              <v-list-item-icon>
                <v-icon>mdi-sync</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Toegewezen registraties</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-else>
            <v-list-item
                dense
                router :to="{ name: 'dashboard'}"
                exact
            >
              <v-list-item-icon>
                <v-icon>mdi-home-outline</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Dashboard</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-subheader>Formulieren</v-subheader>

            <v-list-item
                dense
                router :to="{ name: 'folder_list'}"
                exact
            >
              <v-list-item-icon>
                <v-icon>mdi-text-box-outline</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Formulier invullen</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
                dense
                router :to="{ name: 'unsend_registration_list'}"
                exact
            >
              <v-list-item-icon>
                <v-icon>mdi-sync</v-icon>
                <v-badge :content="unsendRegistrations" v-if="unsendRegistrations" overlap></v-badge>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Opgeslagen registraties</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
                dense
                router :to="{ name: 'assigned_registration_list'}"
                exact
            >
              <v-list-item-icon>
                <v-icon>mdi-share</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Toegewezen registraties</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
                v-if="userCanNavigateToPage('form_list_admin')"
                dense
                router :to="{ name: 'form_list_admin'}"
                exact
            >
              <v-list-item-icon>
                <v-icon>mdi-text-box-edit-outline</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Formulieren beheren</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
                v-if="userHasPermission('eform manage forms')"
                dense
                router :to="{ name: 'folder_list_admin'}"
                exact
            >
              <v-list-item-icon>
                <v-icon>mdi-folder-open</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Mappen beheren</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-subheader v-if="userHasPermission('eform edit datasources')">Databronnen</v-subheader>

            <v-list-item
                v-if="userHasPermission('eform create datasources')"
                dense
                router :to="{ name: 'datasource_list'}"
                exact
            >
              <v-list-item-icon>
                <v-icon>mdi-database</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Databronnen</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-subheader v-if="userHasPermission('eform view client') || userHasPermission('eform client add users') || userHasPermission('add client credits')">
              {{ $store.getters.CurrentClientName }}
            </v-subheader>

            <v-list-item
                v-if="userHasPermission('eform view client')"
                dense
                router :to="{ name: 'client_view', params: { client_id: this.$store.getters.CurrentClientUUID }}"
                exact
            >
            <!-- router :to="clientOverviewRoute"  -->
              <v-list-item-icon>
                <v-icon>mdi-domain</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Overzicht</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
                v-if="userHasPermission('eform client add users')"
                dense
                router :to="{ name: 'client_users', params: { client_id: this.$store.getters.CurrentClientUUID }}"
                exact
            >
              <v-list-item-icon>
                <v-icon>mdi-account-group</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Gebruikers</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
                v-if="userHasPermission('view user group')"
                dense
                router :to="{ name: 'client_user_groups', params: { client_id: this.$store.getters.CurrentClientUUID }}"
                exact
            >
              <v-list-item-icon>
                <v-icon>mdi-account-multiple</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Groepen</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
                v-if="userHasPermission('add client credits')"
                dense
                router :to="{ name: 'client_credits', params: { client_id: this.$store.getters.CurrentClientUUID } }"
                exact
            >
              <v-list-item-icon>
                <v-icon>mdi-plus-circle-multiple</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Credits kopen</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <template v-if="userHasRole('admin')">
              <v-subheader>
                Admin
              </v-subheader>

              <v-list-item
                  dense
                  router :to="{ name: 'client_list_admin' }"
                  exact
              >
                <v-list-item-icon>
                  <v-icon>mdi-domain</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>Klanten beheer</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                  dense
                  router :to="{ name: 'admin_dashboard' }"
                  exact
              >
                <v-list-item-icon>
                  <v-icon>mdi-chart-line</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>Admin dashboard</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>


          </template>

        </v-list>
      </template>

    </v-navigation-drawer>

  </div>
</template>
<script>
import {mapGetters} from "vuex";
import userHelpers from "@/mixins/userHelpers";
import _ from "lodash";

export default {
  name: 'NavBar',
  mixins: [
    userHelpers,
  ],
  data() {
    return {
      onlyFiller: false,
      drawer: false,
      group: null,
      avatarClickCount: 0,
      appVersion: process.env.VUE_APP_VERSION,
      selectedClient: null,
    }
  },
  watch: {
    group () {
      this.drawer = false;
    },
    CurrentClient () {
      // Make sure the selectedClient is updated when the selected client changes in the auth.js state.
      this.selectedClient = this.CurrentClient;
    }
  },
  mounted() {
    this.onlyFiller = this.$store.getters.userIsFiller;
    this.selectedClient = this.CurrentClient;
    if (this.$vuetify.breakpoint.mdAndUp) {
      this.drawer = true;
    }
  },
  computed : {
    ...mapGetters({User: "StateUser", CurrentClient: "CurrentClient", CurrentClientName: "CurrentClientName"}),
    isLoggedIn: function(){ return this.$store.getters.isAuthenticated},
    drawerTemporary() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    unsendRegistrations() {
      return this.$store.state.unsyncedRegistrations.length
    },
    clientOptions() {
      let clientArray = [];
      if (this.User.clients) {
        for (let i = 0; i < this.User.clients.length; i++) {
          clientArray.push({'value': this.User.clients[i].id, 'text': this.User.clients[i].name});
        }
      }
      return clientArray;
    },
    clientOverviewRoute() {
      if (this.userHasRole('admin')) {
        return { name: 'client_list_admin'};
      }
      else {
        return { name: 'client_list_assigned'};
      }
    }
  },
  methods: {
    async activeClientInputChange(value) {
      // No client or client doesn't exist (for user). Don't change values
      if (_.isEmpty(value) || !this.User.clients.find(client => client.id === value)) {
        return;
      }

      // Only set value if it differs from the current
      if (value !== this.CurrentClient) {
        await this.$store.commit('setCurrentClient', value);

        if (this.$route.name !== 'dashboard') {
          await this.$router.push({name: 'dashboard'});
        }
        else if(this.drawerTemporary) {
          this.drawer = false;
        }
        else {
          this.$refs.activeClientAutocomplete.blur();
        }
      }
    },
    activeClientInputFocusLost() {
      // If user isn't typing anymore but the selectedClient is still empty revert it.
      if ((_.isEmpty(this.selectedClient) || !this.User.clients.find(client => client.id === this.selectedClient)) && this.CurrentClient !== null) {
        this.selectedClient = this.CurrentClient;
      }
    },
    toggleDrawer() {
      this.drawer = !(this.drawer && this.drawer === true);
    },
    onAvatarClick() {
      // Increment avatar click counter
      this.avatarClickCount++
      if (this.avatarClickCount > 9) {
        this.avatarClickCount = 0
        this.$toast.success("Bericht is gelogged in Rollbar.");
        this.$rollbar.info('User clickd avatar 10 times');
        // Redirect to ios debugging page
        this.$router.push({name: 'ios_debugging'});
      }
    }
  },
}
</script>
<style lang="scss" scoped>
.hamburger_btn {
  z-index: 10;
}
.nav_drawer {
  z-index: 11;
}
.logout {
  text-decoration: none;
}
</style>
