export default {
    methods: {
        formattedValue(element, value) {
            switch (element.elementType) {
                case 'textarea':
                    if (value) {
                        return value.replace(/(?:\r\n|\r|\n)/g, '<br>');
                    }
                    else {
                        return '';
                    }
                case 'checkbox_single':
                    if (value === true) {
                        return 'Ja';
                    }
                    else {
                        return 'Nee';
                    }
                case 'checkbox_multiple':
                    return this.formatCheckboxMultiple(element, value);
                case 'radio':
                case 'select':
                    return this.formatBox(element, value)
                case 'date':
                    return this.$options.filters.formatDateOnly(value)
                case 'datetime':
                    return this.$options.filters.formatDate(value)
                case 'file':
                case 'image':
                case 'signature':
                    var returnString = '';
                    if (Array.isArray(value)) {
                        // Loop over the uploaded images if there are any
                        for(let i = 0; i < value.length; i++) {
                            for (let j = 0; j < value[i].length; j++) {
                                if (element.elementType === 'image' || element.elementType === 'signature') {
                                    returnString = returnString + this.formatImageLink(value[i][j]);
                                }
                                else {
                                    returnString = returnString + this.formatFileLink(value[i][j]);
                                }
                            }

                            if (i < (value.length -1)) {
                                returnString += '<br />';
                            }
                        }

                        return returnString;
                    }

                    break;
                case 'map':
                    return this.formatMapValues(value);
                case 'search':
                    return this.formatSearchValues(value);
                case 'gps':
                    return this.formatGpsValues(value);
                case 'tracking':
                    return this.formatTrackingValues(value);
                case 'stateTransition':
                    return this.formatStateTransitionValues(element, value);
            }

            if (value) {
                if (typeof value === 'string') {
                    return value.replace(/<\/?[^>]+(>|$)/g, "");
                }
                else {
                    return value;
                }
            }
            else {
                return '';
            }
        },
        formatCheckboxMultiple(element, value) {
            // Create a key/value array of the options instead of a numeric array
            let options = this.getElementOptionsKeyed(element);
            // Output the options
            let returnString = '';
            if (Array.isArray(value)) {
                returnString += '<ul>';
                for (let i = 0; i < value.length; i++) {
                    returnString += '<li>' + options[value[i]] + '</li>';
                }
                returnString += '</ul>';
                return returnString;
            }
            else {
                return '';
            }
        },
        formatBox(element, value) {
            // Create a key/value array of the options instead of a numeric array
            let options = this.getElementOptionsKeyed(element);
            // Output the options
            return options[value]
        },
        formatFileLink(value) {
            if (value['url'] && value['name']) {
                // Return online uploaded file
                return '<a rel=nofollow" target="_blank" href="'+ value['url'] +'">' + value['name'] + '</a>';
            }
            else if (value['base64']) {
                // Return offline (base64 encoded) file
                return value['filename'];
            }
        },
        formatImageLink(value) {
            if (value['url'] && value['name']) {
                if (this.showImagePreviews) {
                    // Show online uploaded image with link
                    return '<a rel=nofollow" target="_blank" href="'+ value['url'] +'">' + '<img width="400px" src="'+ value['url'] +'" alt="' + value['name'] + '" /></a>'
                }
                else {
                    // Just show file name.
                    return value['name']
                }
            }
            else if (value['base64']) {
                // Show offline (base64 encoded) image
                return '<img width="400px" src="'+ value['base64'] +'" alt="' + value['name'] + '" />'
            }
        },
        formatMapValues(value) {
            if (value && value.features) {
                let features = value.features;
                let featureString = '<div class="mapItems">';

                for(let i = 0; i < features.length; i++) {
                    if (features[i].properties && features[i].properties.name) {
                        featureString += '<table class="eform-results-formatted-table" cellpadding="0" cellspacing="0">';
                        featureString += '<tr><td><strong>Naam</strong></td><td><strong>'+features[i].properties.name + '</strong></td></tr>';

                        if (features[i].properties.data) {
                            for (const [key, value] of Object.entries(features[i].properties.data)) {
                                featureString += '<tr><td>' + key + '</td><td>' + value + '</td></tr>';
                            }
                        }
                        featureString += '</table>';
                    }
                }

                featureString += '</div>';
                return featureString;
            }
        },
        formatSearchValues(value) {
            if (value && value.length) {
                let items = value;
                let itemString = '<div class="searchItems">';

                for(let i = 0; i < items.length; i++) {
                    if (items[i].name) {
                        itemString += '<table class="eform-results-formatted-table" cellpadding="0" cellspacing="0">';
                        itemString += '<tr><td><strong>Naam</strong></td><td><strong>'+items[i].name + '</strong></td></tr>';

                        if (items[i].data) {
                            for (const [key, value] of Object.entries(items[i].data)) {
                                itemString += '<tr><td>' + key + '</td><td>' + value + '</td></tr>';
                            }
                        }
                        itemString += '</table>';
                    }
                }

                itemString += '</div>';
                return itemString;
            }
        },
        formatGpsValues(value) {
            let mapsUri = 'https://www.google.com/maps/search/?api=1&query=';
            if (value && value.lat !== 0 && value.lon !== 0) {
                let coords = value;
                mapsUri += coords.lon + ',' + coords.lat;
                return '<a ref="nofollow noopener" target="_blank" href="'+mapsUri+'">Bekijk op Google Maps</a>';
            }
            else {
                return '';
            }
        },
        formatTrackingValues(value) {
            let returnString = '';

            if (value && value.length > 1) {
                let values = value;

                let starttime = new Date(values.at(0).timestamp);
                let endtime = new Date(values.at(-1).timestamp);

                let trackingUrl = this.$router.resolve({name: 'map_tracking', params: {form_id: this.$route.params.form_id, registration_id: this.$route.params.registration_id}});

                returnString += 'Link: <a href="/'+trackingUrl.href+'">Kaart</a><br />';
                returnString += 'Start route: '+ this.$options.filters.formatDate(starttime.toISOString()) + '<br />';
                returnString += 'Einde route: '+ this.$options.filters.formatDate(endtime.toISOString()) + '<br />';
            }
            return returnString;
        },
        formatStateTransitionValues(element, value) {
            const next_phase = value.next_phase;
            const assigned_user = value.username;
            const triggered = value.triggered;
            const triggered_date = this.$options.filters.formatDate(value.triggered_date);
            if (!triggered) {
                return "";
            }
            let message = 'Deze fase overgang is toegepast op deze registratie op ' + triggered_date + '. De registratie is verplaatst naar fase ' + next_phase + '.';
            if (assigned_user) {
                return message + ' De registratie is toegewezen aan gebruiker ' + assigned_user + '.';
            }
        },
    }
};